<script setup>
import {ref, onMounted, onUnmounted} from "vue";

const props = defineProps({
  speed: {
    type: Number,
    default: 1, // 이동 속도 (pixel/frame)
  },
});

const images = import.meta.glob("@/assets/images/partner/*", {eager: true});

const getImageUrl = (name) => {
  return images[`/src/assets/images/partner/${name}`]?.default || "";
};

const items = ref([
  "partner1.png",
  "partner2.png",
  "partner3.png",
  "partner4.png",
  "partner5.png",
  "partner6.png",
  "partner7.png",
  "partner8.png",
  "partner9.png",
  "partner10.svg",
]);

const trackRef = ref(null);
let animationFrameId;

const startScrolling = () => {
  let currentX = 0;

  const scroll = () => {
    if (trackRef.value) {
      currentX -= props.speed; // 속도만큼 왼쪽으로 이동
      const trackWidth = trackRef.value.scrollWidth / 2; // 원본 한 세트의 너비

      // 한 세트 너비만큼 이동했으면 리셋
      if (Math.abs(currentX) >= trackWidth) {
        currentX = 0;
      }

      trackRef.value.style.transform = `translateX(${currentX}px)`;
    }
    animationFrameId = requestAnimationFrame(scroll);
  };

  scroll();
};

onMounted(() => {
  startScrolling();
});

onUnmounted(() => {
  cancelAnimationFrame(animationFrameId);
});
</script>

<template>
  <div class="py-5 bg-white">
    <div class="slider">
      <div class="slide-track" ref="trackRef">
        <!-- 원본 이미지 -->
        <div class="slide" v-for="(image, index) in items" :key="index">
          <img :src="getImageUrl(image)" alt="" />
        </div>
        <!-- 복제본 이미지 (끊김 없이 이어붙이기) -->
        <div class="slide" v-for="(image, index) in items" :key="'clone-' + index">
          <img :src="getImageUrl(image)" alt="" />
        </div>
      </div>
      <div class="overlay"></div>
    </div>
  </div>
</template>

<style scoped>
.slider {
  position: relative;
  width: 100%;
  overflow: hidden;
  /* height: 5rem; */
  background: white;
}

.slide-track {
  display: flex;
  align-items: center;
  will-change: transform;
}

.slide {
  flex: 0 0 auto;
  padding: 0 4rem;
}

.slide img {
  height: 28px;
  object-fit: contain;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    white 0%,
    rgba(255, 255, 255, 0) 10%,
    rgba(255, 255, 255, 0) 90%,
    white 100%
  );
  pointer-events: none;
}
</style>
