<script setup>
import {onMounted, onUnmounted, watch, ref, provide} from "vue";
import {storeToRefs} from "pinia";
import constants from "@/common/constants.js";
import utils from "@/common/utils.js";
import CsSocket from "@/modules/socket.js";
import {Modal, ModalConfirm, fileDownload} from "@/modules";
import {commonStore, userStore, groupStore} from "@/stores";
import {useRouter, useRoute} from "vue-router";
// Components
import modal from "@/views/modal/modal.vue";
import ZipStatus from "@/views/components/common/zip-status.vue";
import {axiosAppData} from "./modules/axios";

/**
 * Datas
 */
const {userId} = storeToRefs(userStore());
const {zipDownloads} = storeToRefs(commonStore());

/**
 * Lifecycle Hooks
 */
onMounted(() => {
  // Connect to the socket server when the component is mounted
  CsSocket.connect();

  axiosAppData.groupStore = storeToRefs(groupStore());
  axiosAppData.router = useRouter();
});

onUnmounted(() => {
  // Disconnect from the socket server when the component is unmounted
  CsSocket.disconnect();
});

/**
 * Watchers
 */
watch(
  () => userId.value,
  () => {
    CsSocket.join(userId.value);
    CsSocket.onEvent("update_common", async (data) => {
      utils.Common.csConsolelog(`Received project update event: ${data}`);

      if (data.event === constants.EVENT_TYPE.driveZipSuccess) {
        fileDownload(data.value.url, decodeURI(data.value.name));
        setTimeout(() => {
          commonStore().removeZipDownload(data.value.zipId);
        }, 1000);
      }
    });
  }
);

/**
 * Theme Management
 */
function applyTheme(isDarkMode) {
  const theme = isDarkMode ? "dark" : "light";
  document.body.dataset.theme = theme;
}

const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
applyTheme(darkModeMediaQuery.matches);
darkModeMediaQuery.addEventListener("change", (e) => applyTheme(e.matches));

/**
 * Window Event Listeners
 */
window.addEventListener("message", windowMessageEvent);
window.addEventListener("load", windowLoadEvent);
window.addEventListener("resize", windowResizeEvent);

function windowMessageEvent(event) {
  const {event: eventType} = event.data;

  if (eventType === constants.WINDOW_EVENT.signIn) {
    // Handle sign-in event and redirect to the main page
    if (window.opener) {
      window.opener.postMessage({event: constants.WINDOW_EVENT.signIn}, "*");
      window.close();
    } else {
      window.location.href = import.meta.env.VITE_APP_BASE_WEB_URL;
    }
  } else if (eventType === constants.WINDOW_EVENT.redirect) {
    // Handle redirect event
    const redirectUrl = utils.Cookie.getCookie(constants.COOKIE_KEY.redirect);
    utils.Cookie.removeCookie(constants.COOKIE_KEY.redirect);
    window.location.href = redirectUrl;
  }
}
function windowLoadEvent() {
  console.log(document.body.scrollHeight, window.innerHeight);
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 10); // 10ms 지연
}
function windowResizeEvent() {}
</script>

<template>
  <div class="cs-layout full-screen">
    <router-view />

    <div class="zip-container">
      <zip-status
        v-for="(zip, idx) in zipDownloads"
        :key="idx"
        :zipId="zip.zipId"
        :zipName="zip.zipName"
      ></zip-status>
    </div>

    <modal v-if="Modal.isVisible" :width="Modal.modalWidth">
      <slot>
        <component
          :is="Modal.activeModal"
          :params="Modal.modalParams"
          @close="(result) => Modal.onCloseModal(result)"
        />
      </slot>
    </modal>
    <modal v-if="ModalConfirm.isVisible" :width="ModalConfirm.modalWidth">
      <slot>
        <component
          :is="ModalConfirm.activeModal"
          :params="ModalConfirm.modalParams"
          @close="(result) => ModalConfirm.onCloseModal(result)"
        />
      </slot>
    </modal>

    <div
      v-if="false"
      class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 flex flex-col items-center justify-center"
    >
      <div
        class="loader-spinner ease-linear rounded-full border-4 border-gray-200 border-t-blue-500 h-12 w-12 mb-4"
      ></div>
      <h2 class="text-center text-white text-xl font-semibold">Loading...</h2>
      <p class="w-1/3 text-center text-white">
        This may take a few seconds, please don't close this page.
      </p>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/styles/index.scss";

.zip-container {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}
</style>
