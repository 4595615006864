<script setup>
import utils from "@/common/utils.js";

const emits = defineEmits(["remove"]);
const props = defineProps({
  items: {
    type: Array,
  },
  isCancel: {
    type: Boolean,
    default: true,
  },
  showSubTitle: {
    type: Boolean,
    default: false,
  },
});

function handleRemove(item) {
  emits("remove", item);
}
</script>

<template>
  <ul class="">
    <li
      v-for="(item, idx) in props.items"
      :key="idx"
      class="flex items-center h-[3.5rem] px-gap-group border-b border-c-layout hover:bg-c-selected-light"
    >
      <div class="flex items-center justify-between w-full">
        <template v-if="!props.showSubTitle">
          <slot name="single-title" :item="item"></slot>
        </template>
        <template v-else>
          <p class="max-w-[22rem] cs-ellipsis1">
            <span><slot name="title" :item="item"></slot></span>
          </p>
          <p class="w-[8rem] max-w-[8rem] text-c-normal">
            <span><slot name="sub" :item="item"></slot></span>
          </p>
        </template>
      </div>
      <button v-if="isCancel" class="cs-btn-rounded cs-btn-error-after" @click="handleRemove(item)">
        <font-awesome-icon icon="minus" class="text-lg" />
      </button>
    </li>
  </ul>
</template>

<style lang="scss" scoped></style>
