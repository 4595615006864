<script setup>
import {ref, onMounted} from "vue";

const props = defineProps({
  width: {
    type: String,
    default: null,
  },
});

const container = ref();
const content = ref();

const isCenterModal = ref(true);

onMounted(() => {
  checkModalCenter();
  window.addEventListener("resize", () => {
    checkModalCenter();
  });

  const ele = document.getElementById("searchInput");
  if (ele) {
    ele.focus();
  }
});

function checkModalCenter() {
  if (content.value.clientHeight < container.value.clientHeight) {
    isCenterModal.value = true;
  } else {
    isCenterModal.value = false;
  }
}
</script>

<template>
  <div class="modal-container" ref="container">
    <div
      class="modal-wrapper"
      :class="[isCenterModal ? 'modal-wrapper-center' : '']"
      :style="[width ? `width:100%; max-width:${width}` : '']"
      ref="content"
    >
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-container {
  @apply fixed top-0 left-0 w-full overflow-x-auto;
  display: grid;
  place-items: center;
  height: 100vh;
  z-index: 2;
  background-color: rgba($color: #000000, $alpha: 0.85);

  animation: ani-show-modal 0.2s ease-out;
  animation-fill-mode: forwards;
}

.modal-wrapper {
  @apply inline-block relative rounded-xl bg-white;
  @apply max-h-[1000px] m-gap-group;
}

@keyframes ani-show-modal {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>
