<script setup>
import {ref, computed, onMounted, watch, defineEmits} from "vue";
import {storeToRefs} from "pinia";
import apis from "@/apis";
import utils from "@/common/utils";
import {ModalConfirm, ModalComponents} from "@/modules";
import {groupStore} from "@/stores";
import LabelInput from "@/components/label-input.vue";
import ModuleLinkSharing from "@/views/modal/link-sharing/link-sharing";

/**********************************************************
 * Reactive Variables
 *********************************************************/
defineExpose({fetchData, getReceivers, getAddressGroups, removeUser, removeAddress, clear});
const emits = defineEmits(["addUser", "addAddress", "settingAddress", "deleteAddress"]);
const props = defineProps({
  requestType: {
    type: String,
    default: "all",
  },
});

const {groupId} = storeToRefs(groupStore());
const moduleLinkSharing = ref(ModuleLinkSharing());
const moduleLinkSharingAddress = ref(ModuleLinkSharing());

const isLoading = ref(false);

const searchUserNameRef = ref();

onMounted(async () => {
  isLoading.value = true;
  await fetchData();
  isLoading.value = false;

  moduleLinkSharing.value.validateAddUserCB = validateAddUser;
  moduleLinkSharing.value.validateRemoveUserCB = validateRemoveUser;
  moduleLinkSharingAddress.value.validateAddUserCB = validateAddAddress;
  moduleLinkSharingAddress.value.validateRemoveUserCB = validateRemoveAddress;
});

async function fetchData(keyword = null) {
  try {
    const res = await apis.group.getGroupAllSubmitters(groupId.value, props.requestType, keyword);
    moduleLinkSharing.value.externalMembers = res.data.data.entrys;
    moduleLinkSharingAddress.value.externalMembers = res.data.data.addressGroups;

    return true;
  } catch (error) {
    return false;
  }
}

async function handleSearchUser() {
  const keyword = searchUserNameRef.value.getText();
  await fetchData(keyword);
}

function validateAddUser(receivers, addUser) {
  if (!addUser.cellPhone) {
    return {
      status: false,
      message: "휴대전화 정보가 없는 참여자는 등록할 수 없습니다",
    };
  }

  const isSameUser = receivers.some((receiver) => receiver.cellPhone === addUser.cellPhone);
  if (isSameUser) {
    return {
      status: false,
      message: "이미 등록되어 있습니다 ",
    };
  }

  return {
    status: true,
  };
}
function validateRemoveUser(receiver, user) {
  return receiver.cellPhone === user.cellPhone;
}

function validateAddAddress(receivers, addUser) {
  const isSameUser = receivers.some((receiver) => receiver.id === addUser.id);
  if (isSameUser) {
    return {
      status: false,
      message: "이미 등록되어 있습니다 ",
    };
  }

  return {
    status: true,
  };
}
function validateRemoveAddress(receiver, user) {
  return receiver.id === user.id;
}

function getReceivers() {
  return moduleLinkSharing.value.receivers;
}
function getAddressGroups() {
  return moduleLinkSharingAddress.value.receivers;
}

function handleAdd(user) {
  moduleLinkSharing.value.addUser(user);
  emits("addUser", {user: user});
}
function handleAddAddress(user) {
  moduleLinkSharingAddress.value.addUser(user);
  emits("addAddress", {user: user});
}
function removeUser(user) {
  moduleLinkSharing.value.removeUser(user);
}
function removeAddress(user) {
  moduleLinkSharingAddress.value.removeUser(user);
}
function clear() {
  searchUserNameRef.value.setText("");
  moduleLinkSharing.value.clear();
  moduleLinkSharingAddress.value.clear();
}

function isNotExistUser(user) {
  if (0 === user.id) {
    return true;
  }

  return false;
}
async function removeNotExistUser(user) {
  ModalConfirm.value.openModal(
    ModalComponents.ModalCancelConfirm,
    {
      hiddenCancel: false,
      html: '<div class="text-lg">해당 참여자를 삭제합니다.</div>',
    },
    async (result) => {
      if (result.status) {
        try {
          await apis.group.deleteAddressBookItems(groupId.value, 0, [user.cellPhone]);
          await fetchData();
        } catch (error) {
          return false;
        }
      }
    }
  );
}

function handleSettingAddress(address) {
  emits("settingAddress", {address: address});
}
function handleDeleteAddress(address) {
  emits("deleteAddress", {address: address});
}
</script>

<template>
  <div>
    <div class="flex items-center h-[3.5rem] px-gap-group">
      <label-input
        id="searchUserName"
        ref="searchUserNameRef"
        class="w-full"
        type="text"
        placeholder-text="이름 검색"
        height="h-c-form-sm"
        @enter="handleSearchUser"
      >
        <template v-slot:after>
          <div class="w-10 m-auto">
            <button tabindex="-1" @click="handleSearchUser">
              <font-awesome-icon icon="magnifying-glass" class="text-lg" />
            </button>
          </div>
        </template>
      </label-input>
    </div>

    <div class="overflow-y-auto h-[25rem] mt-gap-group">
      <template v-if="isLoading">
        <div
          class="absolute top-0 left-0 right-0 bottom-0 w-full h-full overflow-hidden flex flex-col items-center justify-center"
        >
          <div
            class="loader-spinner ease-linear rounded-full border-4 border-gray-200 border-t-blue-500 h-12 w-12 mb-4"
          ></div>
        </div>
      </template>
      <template v-else>
        <template
          v-if="
            moduleLinkSharing.getExternalMemberCount() ||
            moduleLinkSharingAddress.getExternalMemberCount()
          "
        >
          <ul v-if="moduleLinkSharingAddress.getExternalMemberCount()" class="">
            <li
              v-for="(address, idx) in moduleLinkSharingAddress.externalMembers"
              :key="idx"
              class="exist-user-row"
              @click.stop="handleSettingAddress(address)"
            >
              <label
                class="flex items-center justify-between w-full h-full cursor-pointer"
                :for="'exist' + idx"
              >
                <span class="w-[20rem] cs-ellipsis1">
                  <font-awesome-icon icon="users" class="mr-gap-item text-lg" />
                  {{ address.name }}
                  <span class="text-c-normal">({{ address.entryCount }})</span>
                </span>

                <div class="min-w-[5rem] text-right">
                  <button
                    class="cs-btn cs-btn-rounded cs-btn-primary-after"
                    @click.stop="handleAddAddress(address)"
                  >
                    <font-awesome-icon icon="plus" class="text-lg" />
                  </button>
                  <button
                    class="cs-btn cs-btn-rounded cs-btn-error-after"
                    @click.stop="handleDeleteAddress(address)"
                  >
                    <font-awesome-icon icon="xmark" class="text-lg" />
                  </button>
                </div>
              </label>
            </li>
          </ul>
          <ul v-if="moduleLinkSharing.getExternalMemberCount()" class="">
            <li
              v-for="(user, idx) in moduleLinkSharing.externalMembers"
              :key="idx"
              class="exist-user-row"
            >
              <div class="flex items-center">
                <span class="w-[15rem] cs-ellipsis1">
                  {{ user.name }}
                </span>
                <span class="min-w-[10rem] text-right text-c-normal">
                  {{ utils.String.formatPhoneNumber(user.cellPhone) }}
                </span>
              </div>
              <div class="flex items-center justify-end min-w-[5rem] text-right">
                <button class="cs-btn-rounded cs-btn-primary-after" @click.stop="handleAdd(user)">
                  <font-awesome-icon icon="plus" class="text-lg" />
                </button>
                <template v-if="isNotExistUser(user)">
                  <button
                    class="cs-btn-rounded cs-btn-error-after"
                    @click.stop="removeNotExistUser(user)"
                  >
                    <font-awesome-icon icon="xmark" class="text-lg" />
                  </button>
                </template>
                <template v-else>
                  <div class="w-[2rem]"></div>
                </template>
              </div>
            </li>
          </ul>
        </template>
        <div v-else class="w-full mt-gap-group text-center text-c-dark">
          검색된 참여자 및 참여자 그룹이 없습니다.
        </div>
      </template>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.exist-user-row {
  @apply flex relative items-center justify-between h-[3.5rem] p-gap-group border-b border-c-layout transition-all;
}
</style>
