<script setup>
import {ref} from "vue";
import CsButton from "@/components/loader-button.vue";
/**********************************************************
 * data
 *********************************************************/
const emits = defineEmits(["close"]);
const props = defineProps({
  params: {
    type: Object,
  },
});
const submit = ref();
/**********************************************************
 * 모달 닫기
 *********************************************************/
function onCloseModal() {
  emits("close", {status: false});
}

async function onSubmit() {
  emits("close", {status: true, value: props.params});
}
</script>

<template>
  <div class="mobile-w w-[30rem]">
    <div class="modal-content">
      <div class="mt-gap-section text-center">
        <span v-if="props.params.title" class="text-lg">{{ props.params.title }}</span>
        <div v-if="props.params.html" v-html="props.params.html"></div>
      </div>

      <div class="modal-footer">
        <template v-if="!params.hiddenCancel">
          <button class="w-full h-c-submit" @click="onCloseModal">
            <span class="text-c-normal font-semibold">취소</span>
          </button>
          <span class="mx-5 w-10 border-l"></span>
        </template>
        <button
          ref="submit"
          class="cs-btn w-full h-c-confirm px-c-btn cs-gradient"
          @click="onSubmit"
        >
          <span class="text-white font-semibold">{{
            props.params.confirmTitle ? props.params.confirmTitle : "확인"
          }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mobile-w {
  width: calc(100vw - 5rem);
}
</style>
