<script setup>
import {ref, computed, onMounted, watch} from "vue";
import {storeToRefs} from "pinia";
import apis from "@/apis";
import utils from "@/common/utils";
import {CheckModule} from "@/modules";
import {
  userStore,
  groupStore,
  projectStore,
  projectMembersStore,
  feedbackStore,
  driveStore,
} from "@/stores";
// Components
import LabelInput from "@/components/label-input.vue";
import LoaderButton from "@/components/loader-button.vue";
import ValidationMessage from "@/views/components/common/validation-message.vue";
import Dropmenu from "@/components/dropmenu.vue";
import DropmenuUser from "@/components/dropmenu-user.vue";
import UserContainer from "@/components/user-container.vue";

const {userId, userName} = storeToRefs(userStore());
const {groupId, groupName} = storeToRefs(groupStore());
const {projectId, projectName} = storeToRefs(projectStore());
const {projectMembers, projectMemberCount, pageIndex, currentSubmitter} =
  storeToRefs(projectMembersStore());

const emits = defineEmits(["close"]);
const props = defineProps({
  params: {
    type: Object,
  },
});

const receiverUserId = ref(null);
const receiverUserName = ref(null);
const targets = ref([]);

const message = ref("");
const submitButtonRef = ref();
let tooltip = null;

onMounted(() => {
  receiverUserId.value = props.params.receiverUserId;
  receiverUserName.value = props.params.receiverUserName;
  targets.value = props.params.targets ? props.params.targets : [];

  targets.value = targets.value.filter((val) => val);
});

const receiverName = computed(() => {
  if (!receiverUserId.value) {
    return "관리자";
  }

  return receiverUserName.value;
});

const targetName = computed(() => {
  if (targets.value && targets.value.length) {
    const name = targets.value[0].name;
    const ext = 1 < targets.value.length ? `외 ${targets.value.length - 1}개` : "";
    return name + " " + ext;
  }

  return "";
});

onMounted(() => {});

/**********************************************************
 * 모달 닫기
 *********************************************************/
function closeModal() {
  emits("close", {status: false});
}

async function handleSubmit(done) {
  const resultMessage = message.value.trim();

  if (2 > resultMessage.length || 800 <= resultMessage.length) {
    tooltip = utils.Common.showTippy(
      "#feedbackMessage",
      "2자 이상 800자 이하여야 합니다",
      undefined,
      tooltip
    );

    done();
    return;
  }

  try {
    if (projectMembersStore().isRoleAdmin) {
      await feedbackStore().apiSendFeedback(resultMessage, [
        {user: currentSubmitter.value.user, targets: targets.value},
      ]);
    } else {
      await feedbackStore().apiSendFeedback(resultMessage, [{user: null, targets: targets.value}]);
    }
  } finally {
    done();
    closeModal();
  }
}
</script>

<template>
  <div class="w-[37rem]">
    <div>
      <div class="modal-title">요청 보내기</div>
      <button class="modal-btn-close" @click="closeModal">
        <font-awesome-icon icon="xmark" class="text-lg" />
      </button>
    </div>

    <div class="modal-content">
      <div class="font-semibold">{{ receiverName }}님에게 요청합니다.</div>
      <div class="mt-gap-group p-gap-group bg-c-disabled">
        <div class="rounded-t-lg px-5 py-4 bg-c-kakao-yellow text-c-kakao font-semibold">
          알림톡
        </div>
        <div class="px-6 py-6 rounded-b-lg bg-white">
          <p class="text-c-dark text-md">보낸사람: {{ userName }}</p>
          <!-- <p class="text-c-dark text-md">파일명: {{ targetName }}</p> -->
          <p class="mt-gap-group text-md">"{{ projectName }}"서랍에서 수정 또는 요청이 있습니다.</p>
          <p class="mt-gap-group text-md">내용:</p>

          <div class="cs-form cs-form_textarea">
            <textarea
              id="feedbackMessage"
              class="w-full h-[8rem]"
              placeholder="800자 이하의 내용을 입력해주세요."
              maxlength="800"
              v-model="message"
            ></textarea>
          </div>
          <!-- <validation-message
            :texts="['내용이 없으시면 바로 전송을 눌러주세요, 내용이 비어있는 상태로 전송됩니다.']"
            :type="'info'"
          ></validation-message> -->
        </div>
      </div>

      <div class="modal-footer">
        <loader-button
          ref="submitButtonRef"
          :customCSS="'cs-btn cs-btn-blue w-full modal-confirm'"
          @request="handleSubmit"
        >
          <slot><span class="text-white">보내기</span></slot>
        </loader-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.domain-value {
  width: calc(100% - 14rem) !important;
  margin-left: 0.2rem;
}
.domain-com {
  position: absolute;
  top: 48%;
  right: 2rem;
  transform: translateY(-50%);
}
</style>
