import {toast} from "vue3-toastify";
import constants from "./constants.js";
import utils from "@/common/utils.js";

/******************************************************
 * 토큰 검사
 *****************************************************/
const validateAuth = (to, from, next) => {
  const token = utils.Cookie.getCookie(constants.COOKIE_KEY.token);
  if (!token) {
    window.location.href = getMainUrl() + "/auth/signin";
    return;
  }
};

/******************************************************
 * 이메일 검사
 *****************************************************/
const validateEmail = (email) => {
  if (!email) {
    return {
      status: false,
      message: "내용이 없습니다",
    };
  }

  if (0 === email.trim().length) {
    return {
      status: false,
      message: "이메일 형식이 맞지 않습니다",
    };
  }

  const patt_space = /\s/; // 공백문자
  if (email.match(patt_space)) {
    return {status: false, message: "공백문자는 포함할 수 없습니다"};
  }

  const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (/[\uac00-\ud7af]/.test(email)) {
    return {status: false, message: "이메일에 한글을 사용할 수 없습니다"};
  }

  const result = email.match(mailformat);
  if (!result) {
    return {status: false, message: "이메일 형식이 맞지 않습니다"};
  }

  return {
    status: true,
    message: "",
  };
};

/******************************************************
 * 비밀번호 검사
 *****************************************************/
const validatePassword = (password) => {
  if (!password) {
    return {
      status: false,
      message: "내용이 없습니다",
    };
  }

  let patt_4num1 = /(\w)\1\1\1/; // 같은 영문자&숫자 연속 4번 정규식
  let patt_4num2 = /([\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"])\1\1\1/; // 같은 특수문자 연속 4번 정규식
  let patt_cont = /(0123)|(1234)|(2345)|(3456)|(4567)|(5678)|(6789)|(7890)/; // 연속된 숫자 정규식
  let patt_en = /[a-zA-Z0-9-?.,;:|\)*~`!^\-_+<>@\#$%&]/;
  let hangulcheck = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

  if (!password || 8 > password.length || 30 < password.length) {
    return {status: false, message: "비밀번호는 8자 이상 30자 이하입니다"};
  }
  if (password.match(patt_4num1)) {
    return {status: false, message: "같은 영문자, 숫자 연속 4번 이상은 안됩니다"};
  }
  if (password.match(patt_4num2)) {
    return {status: false, message: "같은 특수문자 연속 4번 이상은 안됩니다"};
  }
  if (password.match(patt_cont)) {
    return {status: false, message: "1234 같은 순서가 있는 숫자는 안됩니다"};
  }
  if (!password.match(patt_en)) {
    return {status: false, message: "영문 및 특수문자만 입력 가능합니다"};
  }
  if (hangulcheck.test(password)) {
    return {status: false, message: "비밀번호에 한글을 사용할 수 없습니다"};
  }

  return {
    status: true,
    message: "",
  };
};

/******************************************************
 * 링크 패스워드 검사
 *****************************************************/
const validateLinkPassword = (password) => {
  if (!password) {
    return {
      status: false,
      message: "내용이 없습니다",
    };
  }

  let patt_4num1 = /(\w)\1\1\1/; // 같은 영문자&숫자 연속 4번 정규식
  let patt_4num2 = /([\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"])\1\1\1/; // 같은 특수문자 연속 4번 정규식
  let patt_cont = /(0123)|(1234)|(2345)|(3456)|(4567)|(5678)|(6789)|(7890)/; // 연속된 숫자 정규식
  let patt_en = /[a-zA-Z0-9-?.,;:|\)*~`!^\-_+<>@\#$%&]/;
  let hangulcheck = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

  if (4 > password.length || 8 < password.length) {
    return "비밀번호는 4자 이상 8자 이하입니다";
  }
  if (password.match(patt_4num1)) {
    return "같은 영문자, 숫자 연속 4번 이상은 안됩니다";
  }
  if (password.match(patt_4num2)) {
    return "같은 특수문자 연속 4번 이상은 안됩니다";
  }
  if (password.match(patt_cont)) {
    return "1234 같은 순서가 있는 숫자는 안됩니다";
  }
  if (!password.match(patt_en)) {
    return "영문 및 특수문자만 입력 가능합니다";
  }
  if (hangulcheck.test(password)) {
    return "비밀번호에 한글을 사용할 수 없습니다";
  }

  return "";
};

/******************************************************
 * 이름 검사
 *****************************************************/
const validateName = (value, isSpace = false) => {
  let pattern1 = isSpace ? /[~!@#$%^&*+|<>?:{}|]/ : /[~!@#$%^&*+|<>?:{}|\s]/;

  if (!value) {
    return {
      status: false,
      message: "내용이 없습니다",
    };
  }

  if (pattern1.test(value)) {
    return {
      status: false,
      message: isSpace
        ? "특수문자를 포함할 수 없습니다"
        : "특수문자 또는 공백 문자를 포함할 수 없습니다",
    };
  } else if (value.length < 2 || value.length > 32) {
    return {
      status: false,
      message: "이름은 2자 이상 32자 이하 입니다",
    };
  }

  return {
    status: true,
    message: "",
  };
};

/******************************************************
 * 파일이름 검사
 *****************************************************/
const validateFileName = (value) => {
  // 파일 이름이 비어있거나 공백만 있는 경우 false 반환
  if (!value || value.trim() === "") {
    return {
      status: false,
      message: "내용이 없습니다",
    };
  }

  // 사용 불가능한 문자를 검사하는 정규식 (Windows 기준)
  const forbiddenPattern = /[\\\/:*?"<>|]/;

  // 정규식에 맞으면 false, 없으면 true 반환
  if (!forbiddenPattern.test(value)) {
    return {
      status: true,
      message: "",
    };
  }

  return {
    status: false,
    message: "특수문자를 포함할 수 없습니다",
  };
};

/******************************************************
 * 전화 검사
 *****************************************************/
const validatePhone = (value) => {
  if (!value) {
    return {
      status: false,
      message: "내용이 없습니다",
    };
  }

  const val = value
    .replace(/[^0-9]/g, "")
    .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3")
    .replace("--", "-");

  const pattern1 = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
  if (!pattern1.test(val) || (1 < val.length && 13 > val.length)) {
    return {
      status: false,
      message: "휴대전화 번호 양식과 다릅니다",
    };
  }

  return {
    status: true,
    message: "",
  };
};

/******************************************************
 * 숫자 검사
 *****************************************************/
const validateNumber = (value) => {
  if (!value) {
    return {
      status: false,
      message: "내용이 없습니다",
    };
  }

  const val = value.replace(/[^0-9]/g, "");

  const pattern1 = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
  if (!pattern1.test(val) || (1 < val.length && 13 > val.length)) {
    return {
      status: false,
      message: "휴대전화 번호 양식과 다릅니다",
    };
  }

  return {
    status: true,
    message: "",
  };
};

/******************************************************
 * 도메인 검사
 *****************************************************/
const validateDomain = (domain) => {
  var pattern1 = /[^a-zA-Z0-9-]/; // 영문, 숫자, 하이픈(-)만 허용
  var pattern2 = /[a-z0-9-]/;
  var pattern3 = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
  var pattern4 = /[A-Z]/;
  var pattern5 = /\s/g;
  var patternOnlyNumbers = /^[0-9]+$/; // 숫자만 입력했는지 확인

  if (domain.match(pattern5)) {
    return {
      status: false,
      message: "공백은 포함할 수 없습니다",
    };
  }
  if (pattern1.test(domain) || !pattern2.test(domain) || pattern3.test(domain)) {
    return {
      status: false,
      message: "한글 및 대문자, 특수문자는 포함할 수 없습니다",
    };
  }
  if (pattern4.test(domain)) {
    return {
      status: false,
      message: "대문자는 포함할 수 없습니다",
    };
  }
  if (!domain || domain.length < 3 || domain.length > 32) {
    return {
      status: false,
      message: "도메인은 3자 이상 32자 이하 입니다",
    };
  }
  if (patternOnlyNumbers.test(domain)) {
    return {
      status: false,
      message: "숫자만 입력할 수 없습니다",
    };
  }
  if (
    [
      "www",
      "landing",
      "about",
      "company",
      "address",
      "owner",
      "owners",
      "admin",
      "admins",
      "member",
      "members",
      "extmember",
      "extMember",
      "extMembers",
      "file",
      "files",
      "drive",
      "cloud",
      "media",
      "video",
      "development",
      "developments",
      "production",
      "productions",
      "test",
      "tests",
      "connectstory",
      "dowple",
    ].includes(domain.trim())
  ) {
    return {
      status: false,
      message: "해당 도메인은 사용할 수 없습니다",
    };
  }

  return {
    status: true,
    message: "",
  };
};

/******************************************************
 * 요금 등급별 업로드 크기
 ******************************************************/
const validateUploadSize = (groupPlanGrade, fileSize) => {
  if (constants.SUBSCRIPTION_PLAN_TYPE.free === groupPlanGrade) {
    if (constants.UPLOAD_SIZE_LIMIT.free <= fileSize) {
      toast.warn("3MB 이하 파일만 업로드 가능합니다", {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }
  } else if (constants.SUBSCRIPTION_PLAN_TYPE.basic === groupPlanGrade) {
    if (constants.UPLOAD_SIZE_LIMIT.basic <= fileSize) {
      toast.warn("100MB 이하 파일만 업로드 가능합니다", {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }
  } else if (constants.SUBSCRIPTION_PLAN_TYPE.standard === groupPlanGrade) {
    if (constants.UPLOAD_SIZE_LIMIT.standard <= fileSize) {
      toast.warn("200MB 이하 파일만 업로드 가능합니다", {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }
  } else if (constants.SUBSCRIPTION_PLAN_TYPE.premium === groupPlanGrade) {
    if (constants.UPLOAD_SIZE_LIMIT.premium <= fileSize) {
      toast.warn("300MB 이하 파일만 업로드 가능합니다", {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }
  }

  return true;
};

export default {
  validateAuth,
  validateEmail,
  validatePassword,
  validateLinkPassword,
  validateName,
  validateFileName,
  validatePhone,
  validateNumber,
  validateDomain,
  validateUploadSize,
};
