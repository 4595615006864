import {createRouter, createWebHistory} from "vue-router";
import axios, {AxiosError, HttpStatusCode} from "axios";

import apis from "@/apis";
import constants from "@/common/constants";
import utils from "@/common/utils";
import {initializeToken} from "@/modules/axios";

import {userStore, groupStore, projectStore, projectMembersStore} from "@/stores";

// Paths
import {RoutePaths} from "@/router/paths";

// Layouts
import SignLayout from "@/views/layout/sign-layout.vue";
import AppLayout from "@/views/layout/app-layout.vue";

// Views
// const Landing = () => import("@/views/signin/landing.vue");
const Test = () => import("@/views/signin/test.vue");
const SigninKakao = () => import("@/views/signin/signin-kakao.vue");
const SigninKakaoCallback = () => import("@/views/signin/signin-kakao-callback.vue");
const SigninNaverCallback = () => import("@/views/signin/signin-naver-callback.vue");
const SigninGoogleCallback = () => import("@/views/signin/signin-google-callback.vue");
const SigninEmail = () => import("@/views/signin/signin-email.vue");
const SignupEmail = () => import("@/views/signin/signup-email.vue");
const FindPassword = () => import("@/views/signin/signup-find-password.vue");
const UpdatePassword = () => import("@/views/signin/signup-update-password.vue");
const MyCellPhone = () => import("@/views/signin/cellphone.vue");
const CreateTeam = () => import("@/views/app/create-team.vue");
const Projects = () => import("@/views/app/projects.vue");
const Submitters = () => import("@/views/app/submitters.vue");
const WorkPlace = () => import("@/views/app/work-place.vue");
const TeamSetting = () => import("@/views/app/team-setting.vue");
const TeamSettingDefault = () => import("@/views/app/setting/team-setting-default.vue");
const TeamSettingMember = () => import("@/views/app/setting/team-setting-member.vue");
const TeamSettingPayment = () => import("@/views/app/setting/team-setting-payment.vue");
const TeamSettingAddress = () => import("@/views/app/setting/team-setting-address.vue");
const Plan = () => import("@/views/app/plan.vue");

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {path: "/", name: "main"},
    // {path: RoutePaths.Landing.path, name: RoutePaths.Landing.name, component: Landing},
    {
      path: "/auth",
      name: "sign",
      component: SignLayout,
      beforeEnter: (to, _, next) =>
        to.name === "sign" ? next({name: RoutePaths.Signin.name}) : next(),
      children: [
        {path: RoutePaths.Signin.path, name: RoutePaths.Signin.name, component: SigninKakao},
        {
          path: RoutePaths.SigninKakao.path,
          name: RoutePaths.SigninKakao.name,
          component: SigninKakaoCallback,
        },
        {
          path: RoutePaths.SigninNaver.path,
          name: RoutePaths.SigninNaver.name,
          component: SigninNaverCallback,
        },
        {
          path: RoutePaths.SigninGoogle.path,
          name: RoutePaths.SigninGoogle.name,
          component: SigninGoogleCallback,
        },
        {
          path: RoutePaths.SigninEmail.path,
          name: RoutePaths.SigninEmail.name,
          component: SigninEmail,
        },
        {
          path: RoutePaths.SignupEmail.path,
          name: RoutePaths.SignupEmail.name,
          component: SignupEmail,
        },
        {
          path: RoutePaths.FindPassword.path,
          name: RoutePaths.FindPassword.name,
          component: FindPassword,
        },
        {
          path: RoutePaths.UpdatePassword.path,
          name: RoutePaths.UpdatePassword.name,
          component: UpdatePassword,
        },
        {
          path: RoutePaths.SignupVerifyEmailCode.path,
          name: RoutePaths.SignupVerifyEmailCode.name,
          beforeEnter: async (to, from, next) => {
            try {
              const res = await apis.account.verifyEmailCode(to.query.code);
              if (constants.ENVIRONMENT.production !== import.meta.env.VITE_NODE_ENV) {
                utils.Cookie.setCookie(constants.COOKIE_KEY.token, res.data.data.token, {
                  expires: "1y",
                });
              }
              initializeToken();
              window.location.href = utils.Common.getBaseWebUrl();
            } catch (error) {
              alert(error);
              window.location.href = utils.Common.getBaseWebUrl();
            }
          },
        },
      ],
    },
    {
      path: RoutePaths.User.path,
      name: RoutePaths.User.name,
      component: AppLayout,
      children: [
        {
          path: RoutePaths.CellPhone.path,
          name: RoutePaths.CellPhone.name,
          component: MyCellPhone,
          beforeEnter: async (to, from, next) => {
            await userStore().apiLoadUserData();
            if (utils.Validate.validatePhone(userStore().userCellPhone).status) {
              window.location.href = utils.Common.getBaseWebUrl();
            } else {
              next();
            }
          },
        },
      ],
    },
    {
      path: RoutePaths.Teams.path,
      name: RoutePaths.Teams.name,
      component: AppLayout,
      beforeEnter: async (to, from, next) => {
        await userStore().apiLoadUserData();

        if (RoutePaths.Teams.name === to.name) {
          next({
            name: RoutePaths.CreateTeam.name,
          });

          return;
        }

        next();
      },
      children: [
        {
          path: RoutePaths.CreateTeam.path,
          name: RoutePaths.CreateTeam.name,
          component: CreateTeam,
          beforeEnter: (_, __, next) => {
            if (utils.Common.getSubdomain() === "www") {
              next();
            } else {
              const url = utils.Common.getBaseWebUrl() + RoutePaths.CreateTeam.path;
              window.location.href = url;
            }
          },
        },
        {
          path: RoutePaths.Plan.path,
          name: RoutePaths.Plan.name,
          component: Plan,
          beforeEnter: async (to, _, next) => {
            try {
              await groupStore().apiGetGroup(utils.Common.getSubdomain());
              next();
            } catch (error) {
              if (axios.isAxiosError(error)) {
                window.location.href = utils.Common.getBaseWebUrl();
              } else {
                alert(error);
              }
            }
          },
        },
      ],
    },
    {
      path: RoutePaths.Team.path,
      name: RoutePaths.Team.name,
      component: AppLayout,
      beforeEnter: async (to, _, next) => {
        utils.Validate.validateAuth(to, null, next);
        try {
          await userStore().apiLoadUserData();
          await groupStore().apiGetGroup(utils.Common.getSubdomain());
          next();
        } catch (error) {
          if (axios.isAxiosError(error)) {
            window.location.href = utils.Common.getBaseWebUrl();
          } else {
            alert(error);
          }
        }
      },
      children: [
        {
          path: RoutePaths.TeamSetting.path,
          name: RoutePaths.TeamSetting.name,
          component: TeamSetting,
          beforeEnter: (to, __, next) => {
            if (utils.Common.getSubdomain() === "www") {
              alert("올바르지 않은 접근 입니다.");
              window.location.href = utils.Common.getBaseWebUrl();
            } else if (groupStore().isGroupMember) {
              if (groupStore().isGroupOwner) {
                if (RoutePaths.TeamSetting.name === to.name) {
                  return next({
                    name: RoutePaths.TeamSettingDefault.name,
                  });
                }
              } else if (
                RoutePaths.TeamSetting.name === to.name ||
                RoutePaths.TeamSettingDefault.name === to.name ||
                RoutePaths.TeamSettingPayment.name === to.name
              ) {
                return next({
                  name: RoutePaths.TeamSettingAddress.name,
                });
              }

              next();
            } else {
              next({
                name: RoutePaths.TeamProjects.name,
              });
            }
          },
          children: [
            {
              path: RoutePaths.TeamSettingDefault.path,
              name: RoutePaths.TeamSettingDefault.name,
              component: TeamSettingDefault,
            },
            {
              path: RoutePaths.TeamSettingMember.path,
              name: RoutePaths.TeamSettingMember.name,
              component: TeamSettingMember,
            },
            {
              path: RoutePaths.TeamSettingPayment.path,
              name: RoutePaths.TeamSettingPayment.name,
              component: TeamSettingPayment,
            },
            {
              path: RoutePaths.TeamSettingAddress.path,
              name: RoutePaths.TeamSettingAddress.name,
              component: TeamSettingAddress,
            },
          ],
        },
        {
          path: RoutePaths.TeamProjects.path,
          name: RoutePaths.TeamProjects.name,
          component: Projects,
          children: [
            {
              path: RoutePaths.Project.path,
              name: RoutePaths.Project.name,
              component: Submitters,
              beforeEnter: async (to, _, next) => {
                try {
                  projectMembersStore().$reset();
                  // await projectMembersStore().apiGetMyProjectMember(
                  //   groupStore().groupId,
                  //   to.params.projectId
                  // );

                  if (RoutePaths.Project.name === to.name) {
                    if (projectMembersStore().isRoleSubmitter) {
                      return next({
                        name: RoutePaths.WorkPlace.name,
                        params: {
                          projectId: to.params.projectId,
                          submitterId: userStore().userId,
                        },
                      });
                    }
                  }

                  return next();
                } catch (error) {
                  if (axios.isAxiosError(error)) {
                    window.location.href = utils.Common.getBaseWebUrl();
                  } else {
                    alert(error);
                  }
                }
              },
              children: [
                {
                  path: RoutePaths.WorkPlace.path,
                  name: RoutePaths.WorkPlace.name,
                  component: WorkPlace,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: RoutePaths.ProjectLink.path,
      name: RoutePaths.ProjectLink.name,
      beforeEnter: async (to, from, next) => {
        try {
          const resLink = await projectStore().apiGetProjectLink(to.params.linkId);
          const resProjectMember = await projectMembersStore().apiJoinProject(
            resLink.data.data.groupId,
            resLink.data.data.projectId
          );

          if (constants.USER_ROLE_TYPE.externalMember === resProjectMember.data.data.role) {
            next({
              name: RoutePaths.WorkPlace.name,
              params: {
                projectId: resLink.data.data.projectId,
                submitterId: resProjectMember.data.data.userId,
              },
            });
          } else {
            next({
              name: RoutePaths.Project.name,
              params: {
                projectId: resLink.data.data.projectId,
              },
            });
          }
        } catch (error) {
          if (error) {
            if (!axios.isAxiosError(error)) {
              window.location.href = utils.Common.getBaseWebUrl();
            }
          }
        }
      },
    },
    {
      path: "/payment/billing",
      name: "payment",
      beforeEnter: async (to, from, next) => {
        try {
          await apis.payment.requestCreateBillingKey(
            to.query.groupId,
            to.query.customerKey,
            to.query.authKey
          );
        } catch (error) {
          console.error(error);
        } finally {
          next({
            name: RoutePaths.TeamSettingPayment.name,
            params: {
              groupId: to.query.groupId,
            },
          });
        }
      },
    },
    {
      path: "/*",
      redirect: "/",
    },
  ],
});

router.beforeEach(async (to, _, next) => {
  const token = utils.Cookie.getCookie(constants.COOKIE_KEY.token);
  const subdomain = utils.Common.getSubdomain();

  if (token) {
    // 예외 라우팅 - 해당 라우팅은 무조건 이동
    if (
      new Set([
        // RoutePaths.Signin.name,
        // RoutePaths.SigninEmail.name,
        // RoutePaths.SigninKakao.name,
        // RoutePaths.SigninNaver.name,
        // RoutePaths.SigninGoogle.name,
        // RoutePaths.SignupEmail.name,
        RoutePaths.SignupVerifyEmailCode.name,
        RoutePaths.FindPassword.name,
        RoutePaths.Landing.name,
        RoutePaths.UpdatePassword.name,
        RoutePaths.CreateTeam.name,
        RoutePaths.CellPhone.name,
      ]).has(to.name)
    ) {
      return next();
    } else if ("/payment/billing" == to.path) {
      return next();
    }

    // 리다이렉트
    const redirect = utils.Cookie.getCookie(constants.COOKIE_KEY.redirect);
    utils.Cookie.removeCookie(constants.COOKIE_KEY.redirect);
    if (redirect && redirect.length) {
      window.location.href = redirect;
      return;
    }

    if (subdomain === "www") {
      if (RoutePaths.CreateTeam.name !== to.name) {
        try {
          const res = await apis.group.getRecentGroup();
          if (res.data.data.recentGroup) {
            const groupUrl = utils.Common.createSubdomainUrl(res.data.data.recentGroup.domain);
            window.location.href = groupUrl;
            return;
          } else {
            return next({name: RoutePaths.CreateTeam.name});
          }
        } catch (error) {
          if (error) {
            console.error(error);
          }

          return next({name: RoutePaths.CreateTeam.name});
        }
      }
    } else {
      if ([RoutePaths.Main.name, RoutePaths.Signin.name, RoutePaths.Team.name].includes(to.name)) {
        return next({name: RoutePaths.TeamProjects.name});
      }
    }
  } else {
    const authRoutes = new Set([
      RoutePaths.Signin.name,
      RoutePaths.SigninEmail.name,
      RoutePaths.SigninKakao.name,
      RoutePaths.SigninNaver.name,
      RoutePaths.SigninGoogle.name,
      RoutePaths.SignupEmail.name,
      RoutePaths.SignupVerifyEmailCode.name,
      RoutePaths.FindPassword.name,
      RoutePaths.Landing.name,
      RoutePaths.UpdatePassword.name,
    ]);
    if (!authRoutes.has(to.name)) handleRedirect(subdomain);
  }

  next();
});

function handleRedirect(subdomain) {
  utils.Cookie.setCookie(constants.COOKIE_KEY.redirect, window.location.href);

  window.location.href = subdomain
    ? utils.Common.createSubdomainUrl(subdomain) + "/auth/signin"
    : utils.Common.getBaseWebUrl() + "/auth/signin";
}

export default router;
