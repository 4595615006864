<script setup>
import {ref, computed, onMounted, watch, onUnmounted} from "vue";
import {storeToRefs} from "pinia";
import {useRouter, useRoute} from "vue-router";
const router = useRouter();
const route = useRoute();
import {RoutePaths} from "@/router/paths";
import utils, {Icons} from "@/common/utils";
import {Modal, ModalComponents} from "@/modules";
import {groupStore, projectStore, driveStore, submitStore} from "@/stores";
// Components
import LabelInput from "@/components/label-input.vue";
import LoaderButton from "@/components/loader-button.vue";
import ValidationMessage from "@/views/components/common/validation-message.vue";
import SubmitRuleDetail from "@/views/modal/modal-setting-submit-detail.vue";

/**********************************************************
 * data
 *********************************************************/
const {rules} = storeToRefs(submitStore());
const emits = defineEmits(["close"]);

const submitRuleInputRef = ref();
const addRulebuttonRef = ref();
const selectedRuleIndex = ref(0);
const currentRule = ref(null);
let tooltip = null;
let isLoading = false;

/**********************************************************
 * Lifecycle Hook
 *********************************************************/
onMounted(async () => {
  await submitStore().apiGetRules();

  if (rules.value.length) {
    handleSelect(0);
  }

  submitRuleInputRef.value.setFocus(true);
});

/**********************************************************
 * Event Handlers
 *********************************************************/
function closeModal() {
  emits("close", {status: false});
}

async function handleEnterAddSubmitRule() {
  addRulebuttonRef.value.onClickLoaderBtn();
}
async function handleAddSubmitRule(done) {
  let title = submitRuleInputRef.value.getText();
  title = title.trim();

  if (0 === title.length) {
    done();
    return;
  }

  if (2 > title.length || 64 < title.length) {
    tooltip = utils.Common.showTippy(
      "#submitRuleInput",
      "2자 이상 64자 이하만 허용합니다",
      undefined,
      tooltip
    );
    done();
    return;
  }

  try {
    await submitStore().apiCreateRule(title);

    selectedRuleIndex.value = rules.value.length - 1;
    currentRule.value = rules.value[selectedRuleIndex.value];
    submitRuleInputRef.value.setText("");
    reloadWorkplace();
  } finally {
    done();
  }
}

async function handleSelect(idx) {
  selectedRuleIndex.value = idx;
  if (rules.value.length <= selectedRuleIndex.value) {
    selectedRuleIndex.value = rules.value.length - 1;
  } else if (0 === rules.value.length) {
    selectedRuleIndex.value = 0;
    currentRule.value = null;
    return;
  }

  currentRule.value = rules.value[selectedRuleIndex.value];
}

function handleRowIndex(targetRule, changeType) {
  let newIndex = 0;
  if ("up" === changeType) {
    newIndex = targetRule.rowIdx - 1;
  } else if ("down" === changeType) {
    newIndex = targetRule.rowIdx + 1;
  }

  submitStore().changeIndexSubmitRules(targetRule.rowIdx, newIndex);
  submitStore().apiUpdateRules(rules.value);
  reloadWorkplace();
}

/**********************************************************
 * 제출자료 규칙 삭제
 *********************************************************/
async function handleDeleteRule(rule) {
  await submitStore().apiDeleteRule(rule.ruleId);
  handleSelect(selectedRuleIndex.value);
  reloadWorkplace();
}

function reloadWorkplace() {
  if (RoutePaths.WorkPlace.name === route.name) {
    driveStore().apiGetSubmitter(route.params.submitterId);
  }
}
</script>

<template>
  <div class="w-[40rem]">
    <div>
      <div class="modal-title">제출자료</div>
      <button class="modal-btn-close" @click="closeModal">
        <font-awesome-icon icon="xmark" class="text-lg" />
      </button>
    </div>

    <div class="modal-content">
      <!-- 제출자료 추가 --------------------------------------------------->
      <div class="">
        <div class="flex items-center">
          <label-input
            id="submitRuleInput"
            ref="submitRuleInputRef"
            class="w-full"
            type="text"
            placeholder-text="제출자료 예) 결과 보고서.hwp"
            :is-important="true"
            height="h-c-form-sm"
            :is-focus="true"
            @enter="handleEnterAddSubmitRule"
          >
            <template v-slot:after>
              <div class="flex items-center justify-center pr-gap-item">
                <loader-button
                  ref="addRulebuttonRef"
                  class="cs-btn cs-btn-line cs-btn-rounded min-w-[3.5rem] min-h-[3rem]"
                  @request="handleAddSubmitRule"
                >
                  추가
                </loader-button>
              </div>
            </template>
          </label-input>
        </div>
        <validation-message
          :texts="['참여자가 제출해야할 자료의 정보를 입력해주세요.']"
          :type="'desc'"
        ></validation-message>

        <template v-if="rules.length">
          <div class="mt-gap-section">
            <!-- <validation-message
              :texts="['참여자가 제출해야할 자료의 정보를 입력해주세요.']"
              :type="'info'"
            ></validation-message> -->
            <div class="mb-gap-group text-c-primary-dark">참여자 화면에 표시됩니다.</div>
            <div class="overflow-y-scroll w-full h-[30rem] max-h-[30rem]">
              <submit-rule-detail
                v-for="(rule, idx) in rules"
                :key="idx"
                :rule="rule"
                :extended="true"
                @change-row="handleRowIndex"
                @delete-row="handleDeleteRule"
              ></submit-rule-detail>
              <template v-if="!rules.length">
                <div class="w-full mt-gap-section pt-gap-section text-center">
                  <img :src="Icons.IconEmptyPage" class="m-auto w-24" />
                  <div class="mt-gap-group">등록된 제출자료가 없습니다.</div>
                  <div class="mt-gap-group text-c-normal">
                    <i class="fi fi-sr-bullet"></i>업로드할 제출자료를 설정하세요,<br />참여자는
                    해당 항목에 맞게 자료를 제출할 수 있습니다.
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>

        <div class="mt-gap-section">
          <loader-button
            ref="confirmButtonRef"
            class="mt-gap-group"
            :customCSS="'modal-confirm'"
            @request="closeModal"
          >
            닫기
          </loader-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
