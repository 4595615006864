export const ErrorCodes = {
  unkown_error: "unkown_error",

  unauthorized_email_auth: {
    code: "unauthorized_email_auth",
    description: "인증 메일을 전송했습니다",
  },

  forbidden_request: {code: "forbidden_request", description: "요청 권한이 없습니다"},
  forbidden_user: {code: "forbidden_user", description: "권한이 없습니다"},
  forbidden_group: {code: "forbidden_group", description: "팀에 대한 권한이 없습니다"},
  forbidden_project: {code: "forbidden_project", description: "서랍에 대한 권한이 없습니다"},
  forbidden_project_ban: {
    code: "forbidden_project_ban",
    description: "서랍 접근이 관리자에 의해 불가합니다",
  },
  forbidden_submitter_max_size: {
    code: "forbidden_submitter_max_size",
    description: "서랍의 최대 참여자 인원을 초과했습니다, 팀 플랜을 업그레이드 해주세요",
  },

  /************************************************************************
   * BAD REQUESET
   ************************************************************************/
  bad_certification_email: {
    code: "bad_certification_email",
    description: "인증메일을 다시 보냈습니다",
  },
  bad_already_exist_email: {
    code: "bad_already_exist_email",
    description: "이미 가입된 이메일이 존재합니다",
  },

  /************************************************************************
   * NOT FOUND
   ************************************************************************/
  not_found_user_cellPhone: {
    code: "not_found_user_cellPhone",
    description: "휴대전화 정보가 없습니다",
  },
  not_found_group: {
    code: "not_found_group",
    description: "팀을 찾을 수 없습니다",
  },

  // ----------------------------------------------------------

  // error_already_group: "괸라자로 있는 팀이 존재합니다, 해당 팀을 삭제 후 시도해주세요",
  // error_already_admin: "괸라자로 있는 서랍이 존재합니다, 해당 서랍을 삭제 후 시도해주세요",
  // error_already_exist_email: "error_already_exist_email",
  // error_already_exist_link: "error_already_exist_link",
  // error_already_exist_kakao_provider: "error_already_exist_kakao_provider",
  // error_already_exist_naver_provider: "error_already_exist_naver_provider",
  // error_already_exist_google_provider: "error_already_exist_google_provider",
  // error_project_member_limit: "error_project_member_limit",
  // error_password_decode: "서버의 비밀번호가 정상적이지 않습니다",
  // error_email_decode: "서버의 이메일이 정상적이지 않습니다",
  // error_provider_parser: "error_provider_parser",
  // error_provider_change_password: "이메일로 가입한 계정이 아닙니다",

  // bad_request: "잘 못된 요청입니다",
  // bad_admin_not_delete: "bad_admin_not_delete",
  // bad_only_member: "bad_only_member",
  // bad_password: "bad_password",
  // bad_password_form: "비밀번호 양식이 다릅니다",
  // bad_password_same: "이전 비밀번호와 같습니다",
  // bad_name_form: "bad_name_form",
  // bad_phone_form: "bad_phone_form",
  // bad_company_position_form: "bad_company_position_form",
  // bad_domain_form: "bad_domain_form",
  // bad_already_exist_email: "이미 가입된 이메일이 존재합니다",
  // bad_certification_email: "보내드린 메일에서 인증을 확인해주세요",
  // bad_certification_code: "bad_certification_code",
  // bad_already_exist_admin: "bad_already_exist_admin",
  // bad_already_exist_link: "bad_already_exist_link",
  // bad_already_domain: "같은 도메인이 이미 존재합니다",
  // bad_already_address_book: "같은 그룹이 재합니다",
  // bad_already_cellPhone: "같은 휴대전화번호가 이미 존재합니다",
  // bad_already_exist_filename: "bad_already_exist_filename",
  // bad_recurrence_drive_folder: "bad_recurrence_drive_folder",
  // bad_drive_root_folder: "bad_drive_root_folder",
  // bad_not_found_project_link_try: "bad_not_found_project_link_try",
  // bad_root_folder_create_permission: "bad_root_folder_create_permission",
  // bad_change_plan_once_day: "플랜은 당일 변경이 불가합니다",
  // bad_add_no_more_ext_member: "등록 가능 인원이 초과되었습니다",
  // bad_upload_max_storage_size: "팀의 드라이브 용량이 초과되어 업로드가 불가합니다",
  // bad_upload_max_size: `size 이하 파일만 가능합니다`,

  // not_found_data: "not_found_data",
  // not_found_url: "not_found_url",
  // not_fount_page: "not_fount_page",
  // not_found_user: "사용자를 찾을 수 없습니다",
  // not_found_login: "이메일 또는 비밀번호가 다릅니다",
  // not_found_group: "팀을 찾을 수 없습니다",
  // not_found_group_owner: "not_found_group_owner",
  // not_found_group_invit: "not_found_group_invite",
  // not_found_project: "서랍을 찾을 수 없습니다",
  // not_found_project_folder: "not_found_project_folder",
  // not_found_member: "not_found_member",
  // not_found_notice: "not_found_notice",
  // not_found_feed: "not_found_feed",
  // not_found_email: "해당 이메일을 찾을 수 없습니다",
  // not_found_delete_file: "not_found_delete_file",
  // not_found_project_link: "not_found_project_link",
  // not_found_drive_link: "not_found_drive_link",
  // not_found_drive_folder: "폴더를 찾을 수 없습니다",
  // not_found_drive_file: "파일을 찾을 수 없습니다",
  // not_found_billing_key: "결제 수단을 등록해주세요",
  // not_found_plan_grade: "존재하지 않는 플랜 입니다",
  // not_found_receipt: "결재 이력을 찾을 수 없습니다",

  // unauthorized_email: "이메일 인증을 완료해주세요",
  // unauthorized_email_code: "해당 이메일로 인증메일을 보내드렸습니다, 인증해주세요",
  // unauthorized_user: "사용자를 찾을 수 없습니다",

  // expire_access_token: "세션이 만료되었습니다",
  // expire_reflesh_token: "세션이 만료되었습니다",
  // expire_public_link: "expire_public_link",
  // expire_drive_aws: "expire_drive_aws",
  // expire_auto_code: "인증코드가 만료되었습니다",

  // forbidden_request: "forbidden_request",
  // forbidden_user: "권한이 없습니다",
  // forbidden_user_cellPhone: "휴대전화를 입력해주세요",
  // forbidden_create_group: "forbidden_create_group",
  // forbidden_group: "팀에 대한 권한이 없습니다",
  // forbidden_project: "서랍에 대한 권한이 없습니다",
  // forbidden_project_ban: "forbidden_project_ban",
  // forbidden_drive_file: "forbidden_drive_file",
  // forbidden_drive_folder: "forbidden_drive_folder",
  // forbidden_drive_root_create_folder: "forbidden_drive_root_create_folder",
  // forbidden_drive_submit_create_folder: "forbidden_drive_submit_create_folder",
  // forbidden_drive_root_folder: "forbidden_drive_root_folder",
  // forbidden_drive_upload: "forbidden_drive_upload",
  // forbidden_drive_download: "forbidden_drive_download",
  // forbidden_drive_permission: "forbidden_drive_permission",
  // forbidden_drive_password: "forbidden_drive_password",
  // forbidden_drive_same_password: "forbidden_drive_same_password",
  // forbidden_drive_download_limit: "forbidden_drive_download_limit",
  // forbidden_feed_register: "forbidden_feed_register",
  // forbidden_feed_modify: "forbidden_feed_modify",
  // forbidden_qna_modify: "forbidden_qna_modify",
  // forbidden_project_plan:
  //   "사용중인 플랜의 제공량이 초과되는 서랍으로 플랜을 업그레이드 후 접속 가능합니다",

  // different_domain: "도메인을 확인 해주세요",
  // different_email: "이메일을 확인 해주세요",
  // different_password: "비밀번호를 확인 해주세요",

  // please_try_again: "please_try_again",
};

export default ErrorCodes;
