<script setup>
import {ref, computed, onMounted, watch} from "vue";
import {storeToRefs} from "pinia";
import apis from "@/apis";
import utils from "@/common/utils";
import {userStore, groupStore, projectsStore} from "@/stores";
import {Modal, ModalConfirm, ModalComponents, createExcel} from "@/modules";
import LabelInput from "@/components/label-input.vue";
import LoaderButton from "@/components/loader-button.vue";
import UserContainer from "@/components/user-container.vue";

/**********************************************************
 * Reactive Variables
 *********************************************************/
const {userName} = storeToRefs(userStore());
const {groupId, groupName} = storeToRefs(groupStore());
const {projects} = storeToRefs(projectsStore());

const emits = defineEmits(["close"]);
const props = defineProps({
  params: {
    type: Object,
  },
});

let tooltip = null;

const resultProjects = ref([]);

/**********************************************************
 * Lifecycle Hook
 *********************************************************/
onMounted(async () => {});

/**********************************************************
 * Event Handlers
 *********************************************************/
function closeModal() {
  emits("close", {status: false});
}

function handleAddProject(project) {
  resultProjects.value.push(project);
}

function handleRemoveProject(item) {
  resultProjects.value = resultProjects.value.filter((val) => val.id !== item.id);
}

async function handleSubmit() {
  const projectIds = resultProjects.value.map((val) => val.id);

  try {
    const res = await apis.project.getProjectAllSubmitters(groupId.value, projectIds);
    createExcel(groupName.value, resultProjects.value, res.data.data.submitters);
    closeModal();
  } catch (error) {
    return false;
  }
}
</script>

<template>
  <div class="w-[70rem]">
    <div>
      <div class="modal-title">출석부(Excel)</div>
      <button class="modal-btn-close" @click="closeModal">
        <font-awesome-icon icon="xmark" class="text-lg" />
      </button>
    </div>

    <div class="modal-content relative">
      <div class="my-gap-group text-c-dark">
        서랍별로 참여자 리스트를 출석부(Excel) 형식으로 다운로드 합니다.
      </div>
      <div class="flex items-start w-full">
        <div class="w-full h-full">
          <div
            class="flex items-center w-full h-[3rem] px-gap-group bg-c-table border-y border-c-layout"
          >
            서랍
          </div>
          <div class="overflow-y-auto h-[30rem]">
            <user-container class="" :items="projects" :isCancel="false" :show-sub-title="false">
              <template #single-title="slotProps">
                <div class="flex items-center w-full">
                  <div class="w-full">
                    {{ slotProps.item.name }}
                  </div>
                  <button
                    class="cs-btn cs-btn-rounded cs-btn-primary-after"
                    @click.stop="handleAddProject(slotProps.item)"
                  >
                    <font-awesome-icon
                      icon="plus"
                      class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-lg"
                    />
                  </button>
                </div>
              </template>
            </user-container>
          </div>
        </div>
        <div class="w-[1px] h-[33rem] border-l border-c-layout"></div>
        <div class="w-full h-full">
          <div
            class="flex items-center w-full h-[3rem] px-gap-group bg-c-table-second border-y border-c-layout"
          >
            다운로드할 서랍
          </div>
          <div class="overflow-y-auto h-[30rem]">
            <template v-if="!resultProjects.length">
              <div class="mt-gap-section pt-gap-section text-c-dark text-center">
                왼쪽 리스트에서 서랍을 추가해주세요.
              </div>
            </template>
            <user-container
              class=""
              :items="resultProjects"
              :isCancel="true"
              :show-sub-title="true"
              @remove="handleRemoveProject"
            >
              <template #title="slotProps">
                {{ slotProps.item.name }}
              </template>
            </user-container>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="flex w-[34rem] max-w-[34rem] m-auto">
          <button class="w-full h-c-confirm" @click="closeModal">
            <span class="text-c-normal text-lg font-semibold">취소</span>
          </button>
          <span class="mx-5 w-[1px] h-10"></span>
          <button ref="submit" id="btnLinkNext" class="modal-confirm" @click="handleSubmit">
            <span class="text-white">다운로드</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
