<script setup>
import {ref, computed, onMounted, watch} from "vue";
import {storeToRefs} from "pinia";
import apis from "@/apis";
import utils from "@/common/utils";
import {userStore, groupStore, projectMembersStore, projectStore} from "@/stores";
import {Modal, ModalConfirm, ModalComponents} from "@/modules";
import LabelInput from "@/components/label-input.vue";
import LoaderButton from "@/components/loader-button.vue";
import UserContainer from "@/components/user-container.vue";
import LinkSharingExist from "@/views/modal/link-sharing/modal-project-link-sharing_exist.vue";
import LinkSharingAddress from "@/views/modal/link-sharing/modal-project-link-sharing_address.vue";

/**********************************************************
 * Reactive Variables
 *********************************************************/
const {userName} = storeToRefs(userStore());
const {groupId, groupName} = storeToRefs(groupStore());
const {projectId} = storeToRefs(projectStore());

const emits = defineEmits(["close"]);
const props = defineProps({
  params: {
    type: Object,
  },
});

let tooltip = null;
const viewIndex = ref(0);
const newUserNameRef = ref();
const newUserPhoneNumberRef = ref();
const linkSharingExistRef = ref();
const linkSharingAddressRef = ref();

const currentAddressId = ref(null);
const currentAddressName = ref(null);

// 메세지
function ModuleComment() {
  const comment = ref("");
  let tooltip = null;

  const handleSubmit = async (done) => {
    const existUsers = linkSharingExistRef.value.getReceivers();
    const addressUsers = linkSharingExistRef.value.getAddressGroups();

    const addressBookIds = addressUsers.map((val) => val.id);
    if (!existUsers.length && !addressBookIds.length) {
      tooltip = utils.Common.showTippy("submitButton", "인원을 추가해주세요", null, tooltip);
      done();
      return;
    }

    let userSuccessed = false;
    let addressSuccessed = false;

    try {
      if (existUsers.length) {
        const resUsers = await apis.project.addSubmitters(
          groupId.value,
          projectId.value,
          existUsers,
          comment.value
        );
      }

      userSuccessed = true;
    } catch (error) {}

    try {
      if (addressBookIds.length) {
        const resAddress = await apis.project.addAddressBookSubmitters(
          groupId.value,
          projectId.value,
          addressBookIds,
          comment.value
        );
      }

      addressSuccessed = true;
    } catch (error) {}

    done();

    if (userSuccessed || addressSuccessed) {
      projectStore().apiLoadProjectDetails(projectId.value);
      projectMembersStore().apiGetSubmitters(groupId.value, projectId.value);

      closeModal();
    }
  };

  return {
    comment,

    handleSubmit,
  };
}
const moduleComment = ref(ModuleComment());

const totalUsersCount = computed(() => {
  let totalCount = 0;

  if (linkSharingExistRef.value) {
    const existUsers = linkSharingExistRef.value.getReceivers();
    const addressGroups = linkSharingExistRef.value.getAddressGroups();
    totalCount = existUsers.length + addressGroups.length;
  }

  return totalCount;
});

/**********************************************************
 * Lifecycle Hook
 *********************************************************/
onMounted(async () => {});

/**********************************************************
 * Event Handlers
 *********************************************************/
function closeModal() {
  emits("close", {status: false});
}

function back() {
  viewIndex.value = 0;
}

function next() {
  if (!totalUsersCount.value) {
    tooltip = utils.Common.showTippy(
      "#btnLinkNext",
      "초대할 인원 또는 그룹을 추가하셔야 합니다",
      undefined,
      tooltip
    );

    return;
  }

  viewIndex.value = 2;
}

async function handleAddNewUser() {
  let name = newUserNameRef.value.getText();
  let cellPhone = newUserPhoneNumberRef.value.getText();

  let validateResult = utils.Validate.validateName(name, true);
  if (!validateResult.status) {
    tooltip = utils.Common.showTippy("#newUserName", validateResult.message, undefined, tooltip);
    return;
  }
  validateResult = utils.Validate.validatePhone(cellPhone, true);
  if (!validateResult.status) {
    tooltip = utils.Common.showTippy(
      "#newUserPhoneNumber",
      validateResult.message,
      undefined,
      tooltip
    );
    return;
  }

  newUserNameRef.value.setFocus();
  newUserNameRef.value.setText("");
  newUserPhoneNumberRef.value.setText("");

  cellPhone = cellPhone.replace(/-/g, "");

  try {
    await apis.group.addAddressEntrys(groupId.value, null, [{name: name, cellPhone: cellPhone}]);
    await linkSharingExistRef.value.fetchData();
  } catch (error) {
    return false;
  }
}
async function handleCloseAddress() {
  await linkSharingExistRef.value.fetchData();
  viewIndex.value = 0;
}

function handleGoAddressGroup(event) {
  if (event) {
    currentAddressId.value = event.address.id;
    currentAddressName.value = event.address.name;
  }

  viewIndex.value = 1;
}
function handleDeleteAddressGroup(event) {
  ModalConfirm.value.openModal(
    ModalComponents.ModalCancelConfirm,
    {
      hiddenCancel: false,
      html: '<div class="text-lg">참여자 그룹을 삭제합니다.</div>',
    },
    async (result) => {
      if (result.status) {
        try {
          const res = await apis.group.deleteAddressBook(groupId.value, event.address.id);
          linkSharingExistRef.value.fetchData();
        } catch (error) {
          return false;
        }
      }
    }
  );
}

function handleChangeCellPhone(result) {
  newUserPhoneNumberRef.value.setText(
    utils.String.formatPhoneNumber(utils.String.onlyNumbers(result.value.trim()))
  );
}
</script>

<template>
  <div class="">
    <div>
      <template v-if="0 === viewIndex || 2 === viewIndex">
        <div class="modal-title">참여링크 보내기</div>
        <button class="modal-btn-close" @click="closeModal">
          <font-awesome-icon icon="xmark" class="text-lg" />
        </button>
      </template>
      <template v-else>
        <div class="flex items-center">
          <div class="modal-title">참여자 그룹 추가</div>
        </div>
      </template>
    </div>

    <div class="modal-content relative">
      <section v-show="0 === viewIndex" class="w-[70rem]">
        <div class="flex items-center w-full">
          <div class="flex items-center">
            <label-input
              id="newUserName"
              ref="newUserNameRef"
              class="w-[12.3rem]"
              type="text"
              placeholder-text="이름"
              :is-important="true"
              @enter="handleAddNewUser"
            >
            </label-input>
            <label-input
              id="newUserPhoneNumber"
              ref="newUserPhoneNumberRef"
              class="ml-gap-group"
              type="text"
              placeholder-text="휴대전화번호"
              :is-important="true"
              @change="handleChangeCellPhone"
              @enter="handleAddNewUser"
            >
            </label-input>
            <button
              class="cs-btn cs-btn-line px-c-btn h-c-btn ml-gap-group"
              @click="handleAddNewUser"
            >
              연락처 등록
            </button>
          </div>

          <div class="w-[1px] h-10 mx-gap-group border-l border-c-layout"></div>

          <div class="flex items-center">
            <button class="cs-btn cs-btn-line px-c-btn h-c-btn" @click="handleGoAddressGroup()">
              주소록 그룹 추가
            </button>
          </div>
        </div>
        <div class="w-full mt-gap-group border-t border-c-layout"></div>
        <div class="flex h-[35rem]">
          <div class="w-full">
            <div
              class="flex items-center w-full h-[3rem] mb-gap-group px-gap-group bg-c-table border-b border-c-layout"
            >
              주소록
            </div>
            <link-sharing-exist
              ref="linkSharingExistRef"
              @setting-address="handleGoAddressGroup"
              @delete-address="handleDeleteAddressGroup"
            ></link-sharing-exist>
          </div>
          <div class="w-[1px] border-l border-c-layout"></div>
          <div class="w-full">
            <div
              class="flex items-center w-full h-[3rem] px-gap-group bg-c-table-second border-b border-c-layout"
            >
              카카오톡 초대 전송
            </div>
            <div v-if="!totalUsersCount" class="w-full mt-gap-section pt-gap-group text-center">
              <p class="text-c-dark">왼쪽 리스트에서 링크를 전송할 인원를 추가 해주세요.</p>
              <p class="mt-gap-text text-c-dark">
                등록된 인원이 없다면 상단에 참여자 등록 후 추가해주세요.
              </p>
            </div>
            <div class="overflow-y-auto h-full">
              <div v-if="linkSharingExistRef && linkSharingExistRef.getAddressGroups().length">
                <user-container
                  class=""
                  :items="linkSharingExistRef.getAddressGroups()"
                  :isCancel="true"
                  :show-sub-title="true"
                  @remove="linkSharingExistRef.removeAddress"
                >
                  <template #title="slotProps">
                    <font-awesome-icon icon="users" class="mr-gap-item text-lg" />
                    {{ slotProps.item.name }}
                    <span class="text-c-normal">({{ slotProps.item.entryCount }})</span>
                  </template>
                </user-container>
              </div>
              <div v-if="linkSharingExistRef && linkSharingExistRef.getReceivers().length">
                <user-container
                  class=""
                  :items="linkSharingExistRef.getReceivers()"
                  :isCancel="true"
                  :show-sub-title="true"
                  @remove="linkSharingExistRef.removeUser"
                >
                  <template #title="slotProps">
                    {{ slotProps.item.name }}
                  </template>
                  <template #sub="slotProps">
                    {{ utils.String.formatPhoneNumber(slotProps.item.cellPhone) }}
                  </template>
                </user-container>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="flex w-[34rem] max-w-[34rem] m-auto">
            <button class="w-full h-c-confirm" @click="closeModal">
              <span class="text-c-normal text-lg font-semibold">취소</span>
            </button>
            <span class="mx-5 w-[1px] h-10"></span>
            <button ref="submit" id="btnLinkNext" class="modal-confirm" @click="next">
              <span class="text-white">다음</span>
            </button>
          </div>
        </div>
      </section>
      <section v-if="1 === viewIndex" class="w-[70rem]">
        <link-sharing-address
          ref="linkSharingAddressRef"
          :address-id="currentAddressId"
          :address-name="currentAddressName"
          @close="handleCloseAddress"
        ></link-sharing-address>
      </section>
      <section v-show="2 === viewIndex" class="w-[40rem]">
        <div class="p-gap-group bg-c-disabled">
          <div class="rounded-t-lg px-5 py-4 bg-c-kakao-yellow text-c-kakao font-medium">
            알림톡
          </div>
          <div class="px-6 py-6 rounded-b-lg bg-white">
            <p class="text-md">보낸사람: {{ userName }}</p>
            <p class="mt-1 text-md">받는사람: [선택한 참여자]</p>
            <p class="mt-1 text-md">
              "{{ groupName }}팀의 {{ props.params.project.name }}"에 대한 자료제출을 요청 드립니다.
            </p>
            <br />
            <p class="text-md">제출내용:</p>

            <div class="cs-form cs-form_textarea">
              <textarea
                class="w-full h-[8rem]"
                placeholder="800자 이하의 내용을 입력해주세요."
                maxlength="800"
                v-model="moduleComment.comment"
              ></textarea>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button class="w-full h-c-submit" @click="back">
            <span class="text-c-normal font-semibold">뒤로</span>
          </button>
          <span class="mx-5 w-[1px] h-10"></span>
          <loader-button
            id="submitButton"
            ref="submitButtonRef"
            class="modal-confirm"
            @request="moduleComment.handleSubmit"
          >
            <span class="text-white">보내기</span>
          </loader-button>
        </div>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
