import ExcelJS from "exceljs";
import {saveAs} from "file-saver";

export async function createExcel(groupName, projects, submitters) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("출석부");

  // ✅ 열 너비 고정 추가
  worksheet.columns = [
    {key: "blank", width: 10}, // A열
    {key: "submitterName", width: 20}, // B열 (참여자 이름)
    ...projects.map(() => ({width: 20})), // C, D, E열... 프로젝트 열
    {key: "rowTotal", width: 15}, // 마지막 열 (행 합계)
  ];

  // 1. B열부터 헤더 넣기
  worksheet.getCell(2, 2).value = "참여자 이름"; // B2에 "참여자 이름"

  projects.forEach((project, index) => {
    const col = 3 + index; // C열(3), D열(4), E열(5)...
    worksheet.getCell(2, col).value = project.name;
  });

  worksheet.getCell(2, 3 + projects.length).value = "합계"; // 마지막 열 헤더 (행 합계)

  // 2. 참여자 이름 추출 (중복 제거)
  const submitterNames = [...new Set(submitters.map((submitter) => submitter.userName))];

  // 3. 참여자별로 데이터 넣기
  let currentRow = 3; // 3번 행부터 데이터 시작
  const projectSubmitCounts = new Array(projects.length).fill(0); // 열 별 제출 건수 (초기 0)

  for (const name of submitterNames) {
    worksheet.getCell(currentRow, 2).value = name; // B열(2번 열)에 참여자 이름 넣기

    let rowSubmitCount = 0; // 참여자가 제출한 프로젝트 수

    projects.forEach((project, index) => {
      const col = 3 + index; // C, D, E열...
      const matched = submitters.find(
        (submitter) => submitter.userName === name && submitter.projectId === project.id
      );

      if (matched) {
        worksheet.getCell(currentRow, col).value = formatDate(matched.createdAt);
        rowSubmitCount++; // 제출했으면 카운트
        projectSubmitCounts[index]++; // 해당 프로젝트 참여자 수 +1
      } else {
        worksheet.getCell(currentRow, col).value = "";
      }
    });

    // 참여자 오른쪽에 제출 건수 입력
    worksheet.getCell(currentRow, 3 + projects.length).value = rowSubmitCount;

    currentRow += 1; // 다음 행으로
  }

  // 4. 마지막 줄에 열 합계 넣기
  const totalRow = currentRow;
  worksheet.getCell(totalRow, 2).value = "합계"; // B열에 "합계" 텍스트

  projects.forEach((_, index) => {
    const col = 3 + index;
    worksheet.getCell(totalRow, col).value = projectSubmitCounts[index]; // 프로젝트별 제출 수
  });

  // 행 합계 자리 비워두기 (합계 행의 맨 오른쪽)
  worksheet.getCell(totalRow, 3 + projects.length).value = "";

  // 5. 파일 저장
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, groupName + " 참여자 현황.xlsx");
}

// 날짜 포맷 함수 (ex: 2025-04-07 14:30)
function formatDate(date) {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  const hour = String(d.getHours()).padStart(2, "0");
  const minute = String(d.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day} ${hour}:${minute}`;
}
