<script setup>
import {onMounted, ref} from "vue";
import {storeToRefs} from "pinia";
import {Modal, ModalComponents} from "@/modules";
import {userStore} from "@/stores";
const {userId, userName} = storeToRefs(userStore());

/**********************************************************
 * Reactive Variables
 *********************************************************/
const emits = defineEmits(["close"]);
const props = defineProps({
  params: {
    type: Object,
    default: {},
  },
});

/**********************************************************
 * Lifecycle Hook
 *********************************************************/
onMounted(() => {});

/**********************************************************
 * Event Handlers
 *********************************************************/
// 모달 닫기
function closeModal() {
  // emits("close", {status: false});
  Modal.value.openModal(ModalComponents.ModalChangeUserName, {
    id: userId.value,
    name: userName.value,
  });
}
</script>

<template>
  <div class="mobile-w md:w-[40rem] md:h-full" style="">
    <div class="modal-content">
      <div class="flex w-full h-full text-center">
        <div class="m-auto">
          <div class="text-4xl md:text-2xl">{{ props.params.projectName }}</div>
          <div class="mt-gap-text text-4xl md:text-2xl">서랍에 참여하셨습니다.</div>
        </div>
      </div>

      <div class="modal-footer pt-gap-group">
        <div class="flex w-full m-auto">
          <button ref="submit" id="btnLinkNext" class="modal-confirm" @click="closeModal">
            <span class="text-white">확인</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mobile-w {
  width: calc(100vw - 5rem);
}
// .mobile-h {
//   height: calc(100vh - 3rem);
// }
</style>
