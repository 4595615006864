<script setup>
import {ref, computed, onMounted} from "vue";
import {storeToRefs} from "pinia";
import {useRouter, useRoute} from "vue-router";
const router = useRouter();
const route = useRoute();
import {Modal, ModalComponents} from "@/modules";
import utils, {Icons} from "@/common/utils.js";
import {userStore, groupsStore, groupStore} from "@/stores";
// Components
import Thumbnail from "@/components/thumbnail.vue";
import Dropmenu from "@/components/dropmenu.vue";
import constants from "@/common/constants";
import {RoutePaths} from "@/router/paths";

// Props Definition
const props = defineProps({
  title: {type: String, required: false},
  subTitle: {type: String, required: false},
  thumbnailUrl: {type: String, required: false},
  isDefaultIcon: {type: Boolean, default: true},
  showFeedback: {type: Boolean, default: true},
});

// Store References
const {groups} = storeToRefs(groupsStore());
const {groupId, groupName, groupDomain, groupPlanGrade, groupMember} = storeToRefs(groupStore());
const {userName, userEmail, userThumbnailUrl} = storeToRefs(userStore());

// Reactive Variables
const groupMenusRef = ref();
const userDropmenuRef = ref();
const userPosition = ref({x: 0, y: 0});

const thumbnailImageUrl = computed(() => props.thumbnailUrl || null);

const fullDomain = computed(() => {
  return groupDomain.value + "." + import.meta.env.VITE_APP_BASE_WEB_URL_PATH;
});

// Lifecycle Hook
onMounted(() => {
  userPosition.value.x = window.innerWidth - 178;
  userPosition.value.y = 8;

  window.addEventListener("resize", function () {
    userPosition.value.x = window.innerWidth - 178;
    userPosition.value.y = 8;
  });
});

function handleUserDropmenu(idx) {
  userDropmenuRef.value.toggleDropdown();

  switch (idx) {
    case 0:
      Modal.value.openModal(ModalComponents.ModalProfile);
      break;
    case 1:
      userStore().logout();
      break;
  }
}

/************************************************
 * Groups
 ************************************************/
const isGroupsLoading = ref(false);

/************************************************
 * Computeds
 ************************************************/
const teamGroups = computed(() => {
  return groups.value.filter((val) => {
    return constants.USER_ROLE_TYPE.externalMember !== val.groupMember.role ? val : null;
  });
});

const externalGroups = computed(() => {
  return groups.value.filter((val) => {
    return constants.USER_ROLE_TYPE.externalMember === val.groupMember.role ? val : null;
  });
});

const isCellPhoneRoute = computed(() => {
  return RoutePaths.CellPhone.name === route.name;
});

const groupPlanCss = computed(() => {
  let css = "bg-c-free";

  if (groupPlanGrade.value.length && groupPlanGrade.value) {
    switch (groupPlanGrade.value) {
      case "free":
        css = "cs-badge-c-free";
        break;
      case "basic":
        css = "cs-badge-c-basic";
        break;
      case "standard":
        css = "cs-badge-c-standard";
        break;
      case "premium":
        css = "cs-badge-c-premium";
        break;
    }
  }

  return css;
});

/************************************************
 * Events
 ************************************************/
async function handleShowGroups() {
  if (isCellPhoneRoute.value) {
    return;
  }

  groupMenusRef.value.toggleDropdown();
  isGroupsLoading.value = true;
  await fetchGroupsData();
  isGroupsLoading.value = false;
}

function handleGoSetting() {
  router.push({
    name: RoutePaths.TeamSettingDefault.name,
  });

  groupMenusRef.value.toggleDropdown();
}

function handleCurrentGroup() {
  groupMenusRef.value.toggleDropdown();
}

async function fetchGroupsData() {
  const res = await groupsStore().apiGetMyGroupList();
}

function generateDomainUrl(groupDomain) {
  return groupDomain + "." + import.meta.env.VITE_APP_BASE_WEB_URL_PATH;
}

function handleGoGroup(group) {
  const currentDomain = utils.Common.getSubdomain();
  if (currentDomain !== group.domain) {
    window.location.href = utils.Common.createSubdomainUrl(group.domain);
  }
}

function handleGoCreateTeam() {
  window.location.href = utils.Common.getBaseWebUrl() + RoutePaths.CreateTeam.path;
}
</script>

<template>
  <div class="cs-top-gnb">
    <!-- Left Content: Thumbnail and Title -->
    <div class="flex items-center">
      <Thumbnail
        :imageUrl="thumbnailImageUrl"
        :defaultIcon="isDefaultIcon"
        :size="'3rem'"
        :round="'50%'"
        :borderWidth="1"
        :borderColor="'var(--theme-color-light-border-thumbnail)'"
        :backgroundColor="'var(--theme-color-light-thumbnail-background)'"
      >
        <slot>
          <div class="w-10 h-10">
            <img :src="Icons.IconDowpleSimbol" />
          </div>
        </slot>
      </Thumbnail>

      <div class="flex items-center ml-gap-item">
        <div
          class="min-w-[15rem] rounded-lg cursor-pointer transition-all hover:text-c-first-dark"
          @click="handleShowGroups"
        >
          <div class="flex items-center">
            <h2 class="text-xl font-semibold">{{ props.title }}</h2>
            <div v-if="!isCellPhoneRoute" class="ml-gap-group">
              <font-awesome-icon icon="chevron-down" class="text-lg" />
            </div>
          </div>
          <p class="text-xs text-c-normal">한번의 요청, 모두의 제출과 답변을 쉽게</p>
        </div>
        <dropmenu
          ref="groupMenusRef"
          :css="'w-[1px] h-[1px]'"
          :initText="''"
          :is-change-text="false"
          :position="{top: 8, left: 8}"
          @click="handleShowGroups"
        >
          <template v-slot:content>
            <div class="w-[30rem]">
              <!-- 현재 팀 -->
              <div
                v-if="0 < groupId"
                class="p-gap-group border-b border-c-layout"
                @click="handleCurrentGroup"
              >
                <div class="flex items-center">
                  <Thumbnail
                    :imageUrl="thumbnailImageUrl"
                    :defaultIcon="isDefaultIcon"
                    :size="'2.8rem'"
                    :round="'3px'"
                    :backgroundColor="'var(--theme-color-light-thumbnail-background)'"
                  >
                    <slot>
                      <div class="w-10 h-10">
                        <img :src="Icons.IconDowpleSimbol" />
                      </div>
                    </slot>
                  </Thumbnail>

                  <div class="w-full ml-gap-group">
                    <div class="flex items-center">
                      <span
                        v-if="groupStore().isGroupMember"
                        class="cs-badge cs-badge-xs min-w-[3rem] mr-gap-item text-white font-semibold"
                        :class="[groupPlanCss]"
                      >
                        {{ utils.String.getGroupPlanTextUppercase(groupPlanGrade) }}
                      </span>
                      <span class="text-lg font-semibold">{{ props.title }}</span>
                    </div>
                    <p class="mt-gap-text text-md text-c-light">{{ fullDomain }}</p>
                    <div v-if="groupStore().isGroupMember" class="flex items-center mt-gap-text">
                      <font-awesome-icon :icon="['far', 'hard-drive']" class="mr-gap-item" />

                      <k-progress
                        class="w-[15rem]"
                        :percent="groupStore().currentStoragePercent"
                        :line-height="6"
                        :border="true"
                        :show-text="true"
                        :cut-width="500"
                        :bg-color="'#f0f0f0'"
                        :color="'#2a8dff'"
                      ></k-progress>
                    </div>
                  </div>
                  <div v-if="groupStore().isGroupOwner" class="min-w-[2rem] max-w-[2rem]">
                    <button
                      class="cs-btn cs-btn-rounded cs-btn-primary-after flex items-center justify-center"
                      @click.stop="handleGoSetting"
                    >
                      <font-awesome-icon icon="gear" class="text-lg" />
                    </button>
                  </div>
                </div>
              </div>

              <!-- 팀 리스트 -->
              <div class="overflow-y-auto overflow-x-hidden w-full h-[30rem]">
                <template v-if="isGroupsLoading">
                  <div class="relative w-full h-full">
                    <div
                      class="absolute top-0 left-0 right-0 bottom-0 w-full h-full pb-10 overflow-hidden flex flex-col items-center justify-center"
                    >
                      <div
                        class="loader-spinner ease-linear rounded-full border-4 border-gray-200 border-t-blue-500 h-12 w-12 mb-4"
                      ></div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <div
                      class="w-full px-gap-group py-gap-item bg-c-table border-b border-c-layout text-sm text-c-dark"
                    >
                      팀원으로 참여중인 팀 ({{ teamGroups.length }})
                    </div>
                    <div class="">
                      <div
                        v-for="group in teamGroups"
                        :key="group.id"
                        class="p-gap-group border-b border-c-layout row-hover cursor-pointer"
                        @click="handleGoGroup(group)"
                      >
                        <div class="flex items-center">
                          <Thumbnail
                            :imageUrl="group.thumbnailUrl"
                            :defaultIcon="isDefaultIcon"
                            :size="'2.8rem'"
                            :round="'3px'"
                            :backgroundColor="'var(--theme-color-light-thumbnail-background)'"
                          >
                            <slot>
                              <div class="w-10 h-10">
                                <img :src="Icons.IconDowpleSimbol" />
                              </div>
                            </slot>
                          </Thumbnail>

                          <div class="w-full ml-gap-group">
                            <div class="flex items-center">
                              <span class="font-semibold">{{ group.name }}</span>
                              <span class="ml-gap-item text-sm text-c-normal font-semibold"
                                >({{
                                  utils.String.translateRoleToKR(group.groupMember.role)
                                }})</span
                              >
                            </div>
                            <p class="mt-gap-text text-md text-c-light">
                              {{ generateDomainUrl(group.domain) }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      class="w-full px-gap-group py-gap-item bg-c-table border-b border-c-layout text-sm text-c-dark"
                    >
                      참여자로 참여중인 팀 ({{ externalGroups.length }})
                    </div>

                    <div class="">
                      <div
                        v-for="group in externalGroups"
                        :key="group.id"
                        class="p-gap-group border-b border-c-layout row-hover cursor-pointer"
                        @click="handleGoGroup(group)"
                      >
                        <div class="flex items-center">
                          <Thumbnail
                            :imageUrl="group.thumbnailUrl"
                            :defaultIcon="isDefaultIcon"
                            :size="'2.8rem'"
                            :round="'3px'"
                            :backgroundColor="'var(--theme-color-light-thumbnail-background)'"
                          >
                            <slot>
                              <div class="w-10 h-10">
                                <img :src="Icons.IconDowpleSimbol" />
                              </div>
                            </slot>
                          </Thumbnail>

                          <div class="w-full ml-gap-group">
                            <div class="flex items-center">
                              <span class="font-semibold">{{ group.name }}</span>
                            </div>
                            <p class="mt-gap-text text-md text-c-light">
                              {{ generateDomainUrl(group.domain) }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="!groups.length"
                    class="relative w-full"
                    style="height: calc(100% - 5rem)"
                  >
                    <div class="position-center w-full text-center">
                      <img :src="Icons.IconNoContent" class="m-auto w-20" />
                      <p class="mt-gap-group font-semibold">
                        팀원 또는 참여자로 등록된 팀이 없습니다.
                      </p>
                    </div>
                  </div>
                </template>
              </div>

              <!-- 팀 생성 -->
              <div class="w-full h-[5rem] p-gap-group">
                <button
                  class="cs-btn w-full h-full cs-gradient text-white"
                  @click="handleGoCreateTeam"
                >
                  팀 생성하기
                </button>
              </div>
            </div>
          </template>
        </dropmenu>
      </div>
    </div>

    <!-- Right Content: User Profile and Dropdown Menu -->
    <div class="flex items-center justify-between">
      <button class="flex items-center relative">
        <thumbnail
          :imageUrl="userThumbnailUrl"
          :defaultIcon="false"
          :size="'2.4rem'"
          :round="'50%'"
          :borderWidth="1"
          :borderColor="'var(--theme-color-light-border-thumbnail)'"
          :backgroundColor="'var(--theme-color-light-thumbnail-background)'"
        >
          <img :src="Icons.IconUser" class="min-w-[3.7rem] min-h-[3.7rem]" />
        </thumbnail>
        <dropmenu
          ref="userDropmenuRef"
          :class="'user-menu'"
          :initText="''"
          :isChangeText="false"
          :items="[]"
          :position="{top: userPosition.y, left: userPosition.x}"
        >
          <template v-slot:content>
            <div class="w-[14rem]">
              <div class="p-gap-group text-left border-b border-gray-100">
                <div>{{ userName }}</div>
              </div>
              <ul class="text-left">
                <li @click="handleUserDropmenu(0)">프로필</li>
                <li @click="handleUserDropmenu(1)">로그아웃</li>
              </ul>
            </div>
          </template>
        </dropmenu>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cs-top-gnb {
  @apply flex items-center justify-between w-full h-[55px] pl-5 pr-5 md:pl-5 md:pr-10 border-b border-c-layout bg-c-main;
}

.user-menu {
  @apply absolute w-full h-12;
}

.dropdown-menu {
  @apply absolute min-w-[8rem] shadow-md border rounded-md bg-white top-0 left-0 z-10;
  &.position-right {
    right: 0;
    left: auto;
  }
}
</style>
