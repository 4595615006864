import "./assets/styles/index.scss";

import {createApp, ref} from "vue";

import App from "./App.vue";
import router from "./router/index";

const app = createApp(App);

// global var
app.provide("gContextAttachedFiles", ref(false));
app.provide("gSubmitFileContextMenuData", ref({show: false, coord: {x: 0, y: 0}, file: null}));

// Toast
import Vue3Toastify, {toast} from "vue3-toastify";
import "vue3-toastify/dist/index.css";
app.use(Vue3Toastify, {
  autoClose: 3000,
  hideProgressBar: true,
  transition: toast.TRANSITIONS.SLIDE,
  icon: false,
  limit: 3,
});

import "tippy.js/dist/tippy.css"; // optional for styling

import {library} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {
  faPaperclip,
  faGear,
  faXmark,
  faMagnifyingGlass,
  faTrash,
  faChevronDown,
  faChevronLeft,
  faPen,
  faPlus,
  faFile,
  faAngleRight,
  faAngleLeft,
  faCheck,
  faEllipsis,
  faCircleExclamation,
  faUsers,
  faArrowsRotate,
  faMinus,
  faListUl,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCalendar,
  faHardDrive,
  faEye,
  faEyeSlash,
  faFileExcel,
} from "@fortawesome/free-regular-svg-icons";
library.add(faPaperclip);
library.add(faGear);
library.add(faXmark);
library.add(faEye);
library.add(faEyeSlash);
library.add(faMagnifyingGlass);
library.add(faTrash);
library.add(faChevronDown);
library.add(faChevronLeft);
library.add(faHardDrive);
library.add(faPen);
library.add(faPlus);
library.add(faCalendar);
library.add(faFile);
library.add(faAngleRight);
library.add(faAngleLeft);
library.add(faCheck);
library.add(faEllipsis);
library.add(faCircleExclamation);
library.add(faUsers);
library.add(faArrowsRotate);
library.add(faMinus);
library.add(faFileExcel);
library.add(faListUl);
app.component("font-awesome-icon", FontAwesomeIcon);

// Progress
import KProgress from "k-progress-v3";
app.component("k-progress", KProgress);

// Date Format
import moment from "moment";
app.config.globalProperties.$moment = moment;

// Calendar
import VCalendar from "v-calendar";
import "v-calendar/style.css";
app.use(VCalendar, {});

// Pinia Store
import {createPinia} from "pinia";
app.use(createPinia());
app.use(router);

app.mount("#app");

export default app;
