<script setup>
import {computed, onMounted, ref} from "vue";
import {storeToRefs} from "pinia";
import {useRouter, useRoute} from "vue-router";
const router = useRouter();
const route = useRoute();
import apis from "@/apis";
import {groupStore} from "@/stores";
// Components
import {Modal, ModalComponents} from "@/modules";
import TopGnb from "@/views/layout/top-gnb.vue";

/**********************************************************
 * Data
 *********************************************************/
const {groupId, groupName, groupThumbnailUrl} = storeToRefs(groupStore());

onMounted(async () => {});
</script>

<template>
  <div class="cs-container">
    <top-gnb
      v-if="0 < groupId"
      :title="groupName"
      :thumbnailUrl="groupThumbnailUrl"
      :isDefaultIcon="false"
    ></top-gnb>
    <top-gnb
      v-else
      :title="'다우플'"
      :subTitle="'한번의 요청, 모두의 제출과 답변을 쉽게'"
      :thumbnailUrl="groupThumbnailUrl"
      :isDefaultIcon="false"
    ></top-gnb>
    <router-view></router-view>
  </div>
</template>

<style lang="scss" scoped></style>
