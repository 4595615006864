<script setup>
import {computed, ref, onMounted} from "vue";
import {AxiosError} from "axios";
import {projectStore} from "@/stores";
import utils from "@/common/utils";
// Component
import {toast} from "vue3-toastify";
import LabelInput from "@/components/label-input.vue";
import LoaderButton from "@/components/loader-button.vue";
import ValidationMessage from "@/views/components/common/validation-message.vue";
import {deleteProject} from "@/apis/project";

/**********************************************************
 * Reactive Variables
 *********************************************************/
const emits = defineEmits(["close"]);
const props = defineProps({
  params: {
    type: Object,
  },
});

const nameInputRef = ref();
const deleteInputRef = ref();
const nameConfirmButtonRef = ref();
const deleteConfirmButtonRef = ref();
let tooltip = null;

const projectName = ref("");

const responseNameError = ref("");
const isDeleteMode = ref(false);

/**********************************************************
 * Lifecycle Hook
 *********************************************************/
onMounted(() => {
  if (props.params.project) {
    projectName.value = props.params.project.name;
  }

  nameInputRef.value.setFocus();
});

/**********************************************************
 * Event Handlers
 *********************************************************/
// 모달 닫기
function closeModal() {
  emits("close", {status: false});
}

// 프로젝트 이름 업데이트 요청
function handleEnterChangeProjectName() {
  nameConfirmButtonRef.value.onClickLoaderBtn();
}
async function handleChangeProjectName(done) {
  const newName = nameInputRef.value.getText();
  const validateName = utils.Validate.validateFileName(newName);
  if (!validateName.status) {
    tooltip = utils.Common.showTippy("#nameInput", validateName.message, undefined, tooltip);
    done();
    return;
  }

  try {
    const res = await projectStore().apiUpdateProjectName(props.params.project.id, newName);
    tooltip = utils.Common.showTippy("#nameInput", "변경되었습니다", undefined, tooltip);
  } catch (error) {
    if (error instanceof AxiosError) {
      tooltip = utils.Common.showTippy(
        "#nameInput",
        error.response.data.message,
        undefined,
        tooltip
      );
    }
  } finally {
    done();
  }
}

// 프로젝트 삭제 요청
async function handleDeleteProject(done) {
  const text = deleteInputRef.value.getText();
  if (!text) {
    tooltip = utils.Common.showTippy("#deleteInput", "내용을 입력해주세요", undefined, tooltip);
    done();
    return;
  } else if ("delete" !== text) {
    tooltip = utils.Common.showTippy("#deleteInput", "delete를 입력해주세요", undefined, tooltip);
    done();
    return;
  }

  try {
    const res = await projectStore().apiDeleteProject(props.params.project.id);
    toast.info("서랍이 삭제되었습니다.", {
      position: toast.POSITION.TOP_CENTER,
    });

    emits("close", {status: true, deleteProjectId: props.params.project.id});
  } catch (error) {
    return;
  }
}
</script>

<template>
  <div class="mobile-w md:w-[35rem]">
    <div>
      <div class="modal-title">서랍 설정</div>
      <button class="modal-btn-close" @click="closeModal">
        <font-awesome-icon icon="xmark" class="text-lg" />
      </button>
    </div>

    <div class="modal-content">
      <div class="mt-gap-section1">
        <div class="flex items-center">
          <div class="flex items-center min-w-[8rem] text-c-dark">
            <span class="">생성일</span>
          </div>
          <div>
            {{
              utils.String.dateFormat(
                utils.String.DateFormat.Created_2,
                props.params.project.createdAt
              )
            }}
          </div>
        </div>
        <div class="flex items-center mt-gap-group">
          <div class="flex items-center min-w-[8rem] text-c-dark">
            <span class="">관리자 이름</span>
          </div>
          <div>
            {{ props.params.project.adminProjectMember.userName }}
          </div>
        </div>
        <div class="flex items-center mt-gap-item">
          <div class="flex items-center min-w-[8rem] text-c-dark">
            <span class="">관리자 이메일</span>
          </div>
          <div>
            {{ props.params.project.adminProjectMember.userEmail }}
          </div>
        </div>
      </div>

      <div class="mt-gap-section">
        <label-input
          id="nameInput"
          ref="nameInputRef"
          type="text"
          :placeholderText="'서랍명'"
          height="h-c-form-md"
          :isImportant="true"
          :initText="projectName"
          @enter="handleEnterChangeProjectName"
        >
          <template v-slot:after>
            <div class="p-gap-item">
              <loader-button
                ref="nameConfirmButtonRef"
                :customCSS="'cs-btn cs-btn-line h-full px-c-btn text-md'"
                @request="handleChangeProjectName"
              >
                변경
              </loader-button>
            </div>
          </template>
        </label-input>
        <validation-message
          v-if="responseNameError"
          :texts="[responseNameError]"
          type="error"
        ></validation-message>
      </div>

      <!-- 서랍 삭제 -->
      <div class="mt-gap-section p-5 rounded-lg bg-red-50">
        <p class="modal-form-title text-c-error">서랍 삭제</p>

        <div v-if="!isDeleteMode" class="mt-gap-group">
          <button class="cs-btn w-full h-c-btn bg-c-error text-white" @click="isDeleteMode = true">
            서랍 삭제
          </button>
        </div>
        <div v-if="isDeleteMode" class="mt-gap-group">
          <label-input
            id="deleteInput"
            ref="deleteInputRef"
            type="text"
            placeholderText="'delete'를 입력해주세요"
            :isImportant="true"
          >
          </label-input>
          <validation-message
            :texts="['삭제를 진행하려면 delete를 입력해주세요, 모든 데이터가 즉시 삭제됩니다.']"
            type="error"
          ></validation-message>

          <div class="mt-gap-section">
            <loader-button
              ref="deleteConfirmButtonRef"
              class="w-full h-c-btn text-white"
              :customCSS="'bg-c-error'"
              @request="handleDeleteProject"
            >
              확인
            </loader-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mobile-w {
  width: calc(100vw - 5rem);
}
</style>
