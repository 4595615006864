<script setup>
import {ref, computed, onMounted, watch, onUnmounted} from "vue";
import {storeToRefs} from "pinia";
import utils, {Icons} from "@/common/utils";
import {Modal, ModalComponents} from "@/modules";
import {driveStore, groupStore, projectStore} from "@/stores";
import {DragDropModule} from "@/modules";
// Components
import LabelInput from "@/components/label-input.vue";
import LoaderButton from "@/components/loader-button.vue";
import ValidationMessage from "@/views/components/common/validation-message.vue";
import SubmitFile from "@/views/modal/modal-attached-file.vue";
import apis from "@/apis";

/**********************************************************
 * Reactive Variables
 *********************************************************/
const {groupId, groupName, groupMaxUploadSize} = storeToRefs(groupStore());
const {projectId, projectName, projectRulesCount} = storeToRefs(projectStore());

const dragDrop = ref(DragDropModule());

const emits = defineEmits(["close"]);
const props = defineProps({
  params: {
    type: Object,
    default: null,
  },
});

const fileInputRef = ref();
const attachedFiles = ref([]);

onMounted(async () => {
  try {
    const res = await apis.project.getAttachedFiles(groupId.value, projectId.value);
    attachedFiles.value = res.data.data.attachedFiles.map((file) => {
      return {
        inputFile: null,
        file: file,
      };
    });
  } catch (error) {
    closeModal();
  }

  dragDrop.value.setTargetSelector("dragdrop-wrapper");
  dragDrop.value.setDropCallback(handleCbUploadFile);
});

/**********************************************************
 * 모달 닫기
 *********************************************************/
function closeModal() {
  emits("close", {status: false});
}

/**********************************************************
 * 파일 업로드
 **********************************************************/
function handleUploadFile(event) {
  fileInputRef.value.click();
}
function handleCbUploadFile(files) {
  fileUpload(files);
}
function handleChangeInputFile(event) {
  const inputFiles = event.target.files;
  fileUpload(inputFiles);
}
function fileUpload(inputFiles) {
  const maxUploadSize = groupMaxUploadSize.value;

  for (let i = 0; i < inputFiles.length; i++) {
    if (maxUploadSize <= inputFiles[i].size) {
      toast.warn(
        utils.String.convertBytesToReadableSize(maxUploadSize) + " 이하 파일만 업로드 가능합니다",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      return;
    }
  }

  for (let i = 0; i < inputFiles.length; i++) {
    const inputFile = inputFiles[i];
    attachedFiles.value.push({
      file: {
        id: 0,
        name: inputFile.name,
        size: inputFile.size,
        ext: utils.String.extractFileExtension(inputFile.name),
      },
      inputFile: inputFile,
    });
  }
}
async function handleUploadedAttachedFile(file) {
  reloadWorkplace();
}

async function handleDeleteAttachedFile(file) {
  if (!file.id || 0 === file.id) {
    deleteFileRow(file.id);
    return;
  }

  await driveStore().apiDeleteDriveFiles([
    {
      id: file.id,
      key: file.key,
    },
  ]);
  driveStore().deleteAttachedFile(file.id);
  deleteFileRow(file.id);
  reloadWorkplace();
}

function deleteFileRow(fileId) {
  let rowIdx = 0;
  for (const item of attachedFiles.value) {
    if (fileId === item.file.id) {
      break;
    }
    rowIdx++;
  }

  attachedFiles.value.splice(rowIdx, 1);
}

async function reloadWorkplace() {
  await driveStore().apiGetAttachedFiles();
}
</script>

<template>
  <div class="md:w-[44rem]">
    <input
      name="upload"
      type="file"
      multiple
      ref="fileInputRef"
      charset="utf-8"
      v-show="false"
      @change="handleChangeInputFile"
    />

    <div>
      <div class="modal-title">공통자료</div>
      <button class="modal-btn-close" @click="closeModal">
        <font-awesome-icon icon="xmark" class="text-lg" />
      </button>
    </div>

    <div class="modal-content">
      <div class="">
        <div class="mb-gap-group text-c-primary-dark">
          전체 참여자에게 전달할 파일을 업로드 해주세요.
        </div>
        <div
          class="dragdrop-wrapper relative mt-gap-group h-[13rem] border border-dashed border-c-thumb rounded-xl cursor-pointer transition-all hover:border-c-primary-dark"
          :class="[dragDrop.isDragging ? 'bg-sky-100 border-c-primary-dark' : 'bg-white']"
          @click="handleUploadFile"
          @dragenter="dragDrop.onDragEnter"
          @dragover.prevent="dragDrop.onDragOver"
          @dragleave="dragDrop.onDragLeave"
          @drop.stop.prevent="dragDrop.onDrop"
        >
          <div class="position-center w-full text-center">
            <div class="relative h-[5rem] m-auto">
              <img :src="Icons.IconInbox" class="position-center w-[5rem]" />
            </div>
            <p class="mt-gap-group text-c-dark font-semibold">공통자료자료 업로드</p>
            <div class="flex justify-center w-full">
              <validation-message
                type="info"
                :texts="['해당 영역에 클릭 또는 드래그&드롭으로 파일을 업로드 할 수 있습니다.']"
              ></validation-message>
            </div>
          </div>
        </div>

        <template v-if="attachedFiles.length">
          <div class="mt-gap-section">
            <div class="mb-gap-group text-c-primary-dark">참여자 화면에 표시됩니다.</div>
            <div class="overflow-y-scroll w-full h-[20rem] max-h-[20rem]">
              <submit-file
                v-for="(item, idx) in attachedFiles"
                :key="idx"
                :file="item.file"
                :inputFile="item.inputFile"
                @uploaded="handleUploadedAttachedFile"
                @delete="handleDeleteAttachedFile"
              ></submit-file>
            </div>
          </div>
        </template>

        <div class="mt-gap-section">
          <loader-button
            ref="confirmButtonRef"
            class="mt-gap-group"
            :customCSS="'modal-confirm'"
            @request="closeModal"
          >
            확인
          </loader-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
