import axios from "@/modules/axios";

/*******************************************************************
 * Project Management
 *******************************************************************/

/**
 * Create a new project
 * @param {*} groupId
 * @param {*} name
 * @returns
 */
export function createProject(groupId, name) {
  return axios({
    url: `/groups/${groupId}/projects`,
    method: "post",
    data: {
      name: name,
    },
    params: {
      noToast: 0,
    },
  });
}

/**
 * Get list of projects for a group
 * @param {*} groupId
 * @returns
 */
export function getProjectList(groupId) {
  return axios({
    url: `/groups/${groupId}/projects`,
    method: "get",
  });
}

/**
 * Get project information by project ID
 * @param {*} groupId
 * @param {*} projectId
 * @returns
 */
export function getProjectDetailsById(groupId, projectId) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}`,
    method: "get",
    params: {
      noToast: 0,
    },
  });
}

/**
 * Update project details
 * @param {*} groupId
 * @param {*} projectId
 * @param {*} name
 * @returns
 */
export function updateProject(groupId, projectId, newName) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}`,
    method: "put",
    params: {
      noToast: 0,
    },
    data: {
      newName: newName,
    },
  });
}

/**
 * Delete a project
 * @param {*} groupId
 * @param {*} projectId
 * @param {*} terminate
 * @returns
 */
export function deleteProject(groupId, projectId, terminate) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}`,
    method: "delete",
    params: {
      terminate: terminate,
    },
  });
}

/*******************************************************************
 * Project Link Management
 *******************************************************************/

/**
 * Create a project link
 * @param {*} groupId
 * @param {*} projectId
 * @returns
 */
export function createProjectLink(groupId, projectId) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/link`,
    method: "post",
  });
}

/**
 * Delete a project link
 * @param {*} groupId
 * @param {*} projectId
 * @returns
 */
export function deleteProjectLink(groupId, projectId) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/link`,
    method: "delete",
  });
}

/**
 * Update project link settings (e.g., password and deadline)
 * @param {*} groupId
 * @param {*} projectId
 * @param {*} deadline
 * @param {*} password
 * @returns
 */
export function updateProjectLink(groupId, projectId, deadline, password) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/link`,
    method: "put",
    data: {
      deadline: deadline,
      password: password,
    },
  });
}

/**
 * Get project link information
 * @param {*} linkId
 * @returns
 */
export function getProjectLink(linkId) {
  return axios({
    url: `/links/${linkId}`,
    method: "get",
  });
}

/**
 * Validate project link password
 * @param {*} linkId
 * @param {*} password
 * @returns
 */
export function validateProjectLinkPassword(linkId, password) {
  return axios({
    url: `/links/${linkId}/password/validate`,
    method: "post",
    data: {
      password: password,
    },
  });
}

/*******************************************************************
 * Project Members Management
 *******************************************************************/

/**
 * Add a new member to the project
 * @param {*} groupId
 * @param {*} projectId
 * @returns
 */
export function addProjectMember(groupId, projectId) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/members`,
    method: "post",
  });
}

/**
 * Get information of the current project member
 * @param {*} groupId
 * @param {*} projectId
 * @returns
 */
export function getMyProjectMember(groupId, projectId) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/members/my`,
    method: "get",
  });
}

/**
 * Get the list of all project members
 * @param {*} groupId
 * @param {*} projectId
 * @returns
 */
export function getProjectMembers(groupId, projectId, pageIndex = 0, targetSubmitterId = null) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/members`,
    method: "get",
    params: {
      pageIndex: pageIndex,
      targetSubmitterId: targetSubmitterId,
    },
  });
}

/**
 * Get the list of all project members
 * @param {*} groupId
 * @param {*} projectId
 * @returns
 */
export function getProjectMember(groupId, projectId, submitterId) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/members/${submitterId}`,
    method: "get",
  });
}

/*******************************************************************
 * Project Submit Management
 *******************************************************************/

/**
 * Add new submit rules for the project
 * @param {*} groupId
 * @param {*} projectId
 * @param {*} rules
 * @returns
 */
export function addSubmitRules(groupId, projectId, rule) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/submits/rules`,
    method: "post",
    data: {
      rule: rule,
    },
  });
}

/**
 * Update submit rules for the project
 * @param {*} groupId
 * @param {*} projectId
 * @param {*} rules
 * @returns
 */
export function updateSubmitRules(groupId, projectId, rules) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/submits/rules`,
    method: "put",
    data: {
      rules: rules,
    },
  });
}

/**
 * Get submit rules for the project
 * @param {*} groupId
 * @param {*} projectId
 * @returns
 */
export function getSubmitRules(groupId, projectId) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/submits/rules`,
    method: "get",
  });
}

/**
 * Delete a specific submit rule
 * @param {*} groupId
 * @param {*} projectId
 * @param {*} ruleId
 * @returns
 */
export function deleteSubmitRule(groupId, projectId, ruleId) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/submits/rules/${ruleId}`,
    method: "delete",
  });
}

/**
 * 참여자의 제출정보 및 파일 요청
 * @param {*} groupId
 * @param {*} projectId
 * @param {*} submitterId
 * @returns
 */
export function getSubmitter(groupId, projectId, submitterId) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/submitters/${submitterId}`,
    method: "get",
  });
}

/*******************************************************************
 * File Management in Drive
 *******************************************************************/

/**
 * Upload a file to the project drive
 * @param {*} groupId
 * @param {*} projectId
 * @param {*} file
 * @param {*} type
 * @param {*} parentFolderId
 * @param {*} ruleId
 * @returns
 */
export function uploadDriveFile(groupId, projectId, file, type, submitterUserId, ruleId) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/drive/signed-url`,
    method: "post",
    data: {
      file: file,
      type: type,
      submitterUserId: submitterUserId,
      ruleId: ruleId,
    },
  });
}

/**
 * Download file from the project drive
 * @param {*} groupId
 * @param {*} projectId
 * @param {*} fileId
 * @returns
 */
export function downloadDriveFile(groupId, projectId, fileId) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/drive/signed-url`,
    method: "get",
    params: {
      fileId: fileId,
    },
  });
}

/**
 * Update a file in the project drive
 * @param {*} groupId
 * @param {*} projectId
 * @param {*} file
 * @returns
 */
export function updateDriveFile(groupId, projectId, file) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/drive/files/${file.id}`,
    method: "put",
    data: {
      file: file,
    },
  });
}

/**
 * Delete files from the project drive
 * @param {*} groupId
 * @param {*} projectId
 * @param {*} files
 * @returns
 */
export function deleteDriveFiles(groupId, projectId, files) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/drive/files/delete`,
    method: "post",
    data: {
      files: files,
    },
  });
}

/**
 * Download a compressed archive of selected files
 * @param {*} groupId
 * @param {*} projectId
 * @param {*} zipId
 * @param {*} zipFileName
 * @param {*} folders
 * @param {*} files
 * @returns
 */
export function downloadDriveArchiveFiles(groupId, projectId, fileIds, zipId, zipFileName) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/drive/archive`,
    method: "post",
    data: {
      fileIds: fileIds,
      zipId: zipId,
      zipFileName: zipFileName,
    },
  });
}

export function downloadDriveArchiveUsers(
  groupId,
  projectId,
  submitterUserIds,
  zipId,
  zipFileName
) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/drive/archive`,
    method: "post",
    data: {
      submitterUserIds: submitterUserIds,
      zipId: zipId,
      zipFileName: zipFileName,
    },
  });
}

export function getDownloadHistory(groupId, projectId, fileId) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/drive/files/${fileId}`,
    method: "get",
  });
}

export function getAttachedFiles(groupId, projectId) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/drive/attached-files`,
    method: "get",
  });
}

/*******************************************************************
 * Submitters Management
 *******************************************************************/

/**
 * Add new submitters to the project
 * @param {*} groupId
 * @param {*} projectId
 * @param {*} userIds
 * @param {*} message
 * @returns
 */
export function addSubmitters(groupId, projectId, addUsers, message) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/submitters`,
    method: "post",
    data: {
      addUsers: addUsers,
      message: message,
    },
  });
}

/**
 * Add address book submitters to the project
 * @param {*} groupId
 * @param {*} projectId
 * @param {*} addressBookIds
 * @param {*} message
 * @returns
 */
export function addAddressBookSubmitters(groupId, projectId, addressBookIds, message) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/address-submitters`,
    method: "post",
    data: {
      addressBookIds: addressBookIds,
      message: message,
    },
  });
}

/**
 * Update a project member's details
 * @param {*} groupId
 * @param {*} projectId
 * @param {*} values
 * @returns
 */
export function updateProjectMember(groupId, projectId, submitterId, values) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/members/${submitterId}`,
    method: "put",
    data: values,
  });
}

/**
 * Add a comment on a file in the project drive
 * @param {*} groupId
 * @param {*} projectId
 * @param {*} fileId
 * @param {*} receiverUserId
 * @param {*} receiverUserName
 * @param {*} text
 * @returns
 */
export function addProjectFileComment(
  groupId,
  projectId,
  fileId,
  receiverUserId,
  receiverUserName,
  text
) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/drive/comments`,
    method: "post",
    data: {
      fileId: fileId,
      receiverUserId: receiverUserId,
      receiverUserName: receiverUserName,
      text: text,
    },
  });
}

/**
 * Search workplace
 * @param {*} groupId
 * @param {*} projectId
 * @param {*} keyword
 * @returns
 */
export function searchWorkplace(groupId, projectId, keyword) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/search`,
    method: "get",
    params: {
      noToast: 0,
      keyword: keyword,
    },
  });
}

export function deleteInviteSubmitter(groupId, projectId, cellPhone) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/drive/comments`,
    method: "post",
    data: {
      fileId: fileId,
      receiverUserId: receiverUserId,
      receiverUserName: receiverUserName,
      text: text,
    },
  });
}

export function getProjectAllSubmitters(groupId, projectIds) {
  const queryString = projectIds.join(",");

  return axios({
    url: `/groups/${groupId}/projects/submitters`,
    method: "get",
    params: {
      projectIds: queryString,
    },
  });
}

export default {
  createProject,
  getProjectList,
  getProjectDetailsById,
  updateProject,
  deleteProject,
  createProjectLink,
  deleteProjectLink,
  updateProjectLink,
  getProjectLink,
  validateProjectLinkPassword,
  addProjectMember,
  getMyProjectMember,
  getProjectMembers,
  getProjectMember,
  addSubmitRules,
  updateSubmitRules,
  getSubmitRules,
  deleteSubmitRule,
  getSubmitter,
  uploadDriveFile,
  downloadDriveFile,
  updateDriveFile,
  deleteDriveFiles,
  downloadDriveArchiveFiles,
  downloadDriveArchiveUsers,
  addSubmitters,
  updateProjectMember,
  addProjectFileComment,
  addAddressBookSubmitters,
  searchWorkplace,
  getDownloadHistory,
  getAttachedFiles,
  getProjectAllSubmitters,
};
