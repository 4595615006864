<script setup>
import {ref, computed, onMounted, watch} from "vue";
import {storeToRefs} from "pinia";
import {RoutePaths} from "@/router/paths";
import apis from "@/apis";
import axios from "axios";
import utils from "@/common/utils";
import {groupStore} from "@/stores";
import LabelInput from "@/components/label-input.vue";
import UserContainer from "@/components/user-container.vue";
import LinkSharingExist from "@/views/modal/link-sharing/modal-project-link-sharing_exist.vue";

/**********************************************************
 * Reactive Variables
 *********************************************************/

const emits = defineEmits(["close"]);

const props = defineProps({
  addressId: {
    type: Number,
    default: null,
  },
  addressName: {
    type: String,
    default: "",
  },
});

const {groupId} = storeToRefs(groupStore());

let tooltip = null;
const isLoading = ref(false);
const addressGroupId = ref(null);
const addressGroupName = ref("");
const entrys = ref([]);

const addressNameRef = ref();
const linkSharingExistRef = ref();

onMounted(async () => {
  addressGroupId.value = props.addressId;
  addressGroupName.value = props.addressName;
  addressNameRef.value.setText(addressGroupName.value);

  isLoading.value = true;

  if (props.addressId) {
    await fetchAddressData();
    await fetchSubmitterData();
  } else {
    await createAddressGroup();
  }
  isLoading.value = false;
});

/******************************************************
 * 주소록 그룹 엔트리 가져오기
 ******************************************************/
async function fetchSubmitterData() {
  try {
    const res = await apis.group.getAddressBookItems(groupId.value, addressGroupId.value);
    entrys.value = res.data.data.addressItems;
    return true;
  } catch (error) {
    return false;
  }
}

async function fetchAddressData() {
  try {
    const res = await apis.group.getAddressBookItems(groupId.value, addressGroupId.value);
    entrys.value = res.data.data.addressItems;
    return true;
  } catch (error) {
    return false;
  }
}

/******************************************************
 * 주소록 그룹 생성
 ******************************************************/
async function createAddressGroup() {
  try {
    const res = await apis.group.createAddressBook(groupId.value);
    addressGroupId.value = res.data.data.addressBook.id;
    addressGroupName.value = ""; //res.data.data.addressBook.name;
    addressNameRef.value.setText(addressGroupName.value);
    addressNameRef.value.setFocus();
  } catch (error) {
    return false;
  }
}
async function changeAddressBookName() {
  let newGroupName = addressNameRef.value.getText();

  if (!newGroupName || !newGroupName.length) {
    tooltip = utils.Common.showTippy(
      "#addressNameInput",
      "주소록 그룹 이름을 입력해주세요",
      undefined,
      tooltip
    );
    return false;
  }

  newGroupName = newGroupName.trim();

  const validateName = utils.Validate.validateName(newGroupName, true);
  if (!validateName.status) {
    tooltip = utils.Common.showTippy("#addressNameInput", validateName.message, undefined, tooltip);
    done();
    return false;
  } else if (2 > newGroupName.length || 32 < newGroupName.length) {
    tooltip = utils.Common.showTippy(
      "#addressNameInput",
      "2자 이상 32이자 이하여야 합니다",
      undefined,
      tooltip
    );
    done();
    return false;
  }

  try {
    const res = await apis.group.updateAddressBook(
      groupId.value,
      addressGroupId.value,
      newGroupName
    );
    return true;
  } catch (error) {
    let message = "";
    if (axios.isAxiosError(error)) {
      message = error.response.data.message;
    } else {
      message = "오류가 발생 했습니다.";
    }

    tooltip = utils.Common.showTippy("#addressNameInput", message, undefined, tooltip);
    return false;
  }
}

async function handleAddGroupUser(data) {
  try {
    const res = await apis.group.addAddressEntrys(groupId.value, addressGroupId.value, [data.user]);
    await fetchAddressData();
  } catch (error) {
    linkSharingExistRef.value.removeUser(data.user);
  }
}

async function removeEntry(entry) {
  try {
    const res = await apis.group.deleteAddressBookItems(groupId.value, addressGroupId.value, [
      entry.cellPhone,
    ]);
    entrys.value = entrys.value.filter((val) => val.cellPhone !== entry.cellPhone);
  } catch (error) {}
}

async function handleBack() {
  if (!props.addressId) {
    try {
      const res = await apis.group.deleteAddressBook(groupId.value, addressGroupId.value);
    } catch (error) {
      return false;
    }
  }

  emits("close");
}
async function handleSubmit() {
  if (await changeAddressBookName()) {
    emits("close");
  }
}
</script>

<template>
  <div class="">
    <div>
      <label-input
        id="addressNameInput"
        ref="addressNameRef"
        class="w-full"
        type="text"
        height="h-c-form-md"
        :is-important="true"
        placeholder-text="주소록 그룹 이름"
      >
      </label-input>
    </div>
    <div class="w-full mt-gap-group border-t border-c-layout"></div>
    <div class="h-[35rem]">
      <template v-if="isLoading">
        <div class="relative w-full h-full">
          <div
            class="absolute top-0 left-0 right-0 bottom-0 w-full h-full pb-10 overflow-hidden flex flex-col items-center justify-center"
          >
            <div
              class="loader-spinner ease-linear rounded-full border-4 border-gray-200 border-t-blue-500 h-12 w-12 mb-4"
            ></div>
            <span class="text-c-dark">잠시만 기다려주세요...</span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex w-full">
          <div class="w-full">
            <div
              class="flex items-center w-full h-[3rem] mb-gap-group px-gap-group bg-c-table border-b border-c-layout"
            >
              주소록
            </div>
            <link-sharing-exist
              ref="linkSharingExistRef"
              request-type="''"
              @add-user="handleAddGroupUser"
            ></link-sharing-exist>
          </div>
          <div class="w-[1px] border-l border-c-layout"></div>
          <div class="w-full">
            <div
              class="flex items-center w-full h-[3rem] px-gap-group bg-c-table-second border-b border-c-layout"
            >
              주소록 그룹
            </div>
            <div class="overflow-y-auto h-full">
              <div v-if="entrys && entrys.length" class="">
                <user-container
                  class=""
                  :items="entrys"
                  :isCancel="true"
                  :show-sub-title="true"
                  @remove="removeEntry"
                >
                  <template #title="slotProps">
                    {{ slotProps.item.name }}
                  </template>
                  <template #sub="slotProps">
                    {{ utils.String.formatPhoneNumber(slotProps.item.cellPhone) }}
                  </template>
                </user-container>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="modal-footer">
      <div class="flex w-[34rem] max-w-[34rem] m-auto">
        <button class="w-full h-c-confirm" @click="handleBack">
          <span class="text-c-normal text-lg font-semibold">취소</span>
        </button>
        <span class="mx-5 w-[1px] h-10"></span>
        <button ref="submit" id="btnLinkNext" class="modal-confirm" @click="handleSubmit">
          <span class="text-white">확인</span>
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.exist-user-row {
  @apply flex relative items-center h-[3rem] p-gap-group border-b border-c-layout cursor-pointer transition-all;

  &:hover {
    @apply bg-c-selected-light;
  }
}
</style>
