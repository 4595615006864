<script setup>
import {computed, onMounted, ref} from "vue";
import {storeToRefs} from "pinia";
import apis from "@/apis";
import {useRouter, useRoute} from "vue-router";
const router = useRouter();
const route = useRoute();
import {RoutePaths} from "@/router/paths";
import utils, {Icons} from "@/common/utils";
import {CheckModule} from "@/modules";
import {commonStore, groupStore, projectStore} from "@/stores";
import {HttpStatusCode} from "axios";
// Components
import SearchSubmitter from "./modal-workplace-search-submitter.vue";
import SearchFile from "./modal-workplace-search-file.vue";

const {groupId, groupName} = storeToRefs(groupStore());
const {projectId, projectName, projectRulesCount} = storeToRefs(projectStore());

const emits = defineEmits(["close"]);
const check = ref(CheckModule());
const search = ref(SearchModule());
function SearchModule() {
  const keyword = ref("");
  const searchSubmitters = ref([]);
  const searchSubmitFiles = ref([]);
  const isLoading = ref(false);
  let tooltip = null;

  const handleSearch = async () => {
    if (isLoading.value) {
      return;
    }

    if (2 > keyword.value.length || 32 < keyword.value.length) {
      tooltip = utils.Common.showTippy(
        "#searchInput",
        "2자 이상 32자 이하여야 합니다",
        undefined,
        tooltip
      );
      return;
    }

    if (tooltip) {
      tooltip.destroy();
    }

    isLoading.value = true;

    try {
      const res = await apis.project.searchWorkplace(groupId.value, projectId.value, keyword.value);
      if (HttpStatusCode.Ok === res.status) {
        searchSubmitters.value = res.data.data.submitters;
        searchSubmitFiles.value = res.data.data.files;

        check.value.initializeCheckedList(searchSubmitFiles.value.length);
      }
    } catch (error) {
      console.error(error);
    } finally {
      isLoading.value = false;
    }
  };

  const handleDownload = async () => {
    const checkeds = check.value.getCheckedIndices();
    if (checkeds.length) {
      const fileIds = [];
      for (const index of checkeds) {
        fileIds.push(searchSubmitFiles.value[index].id);
      }

      commonStore().apiZipDownloadForFiles(fileIds);
    }
  };

  return {
    isLoading,
    keyword,
    searchSubmitters,
    searchSubmitFiles,

    handleSearch,
    handleDownload,
  };
}

const allCheckState = computed(() => {
  return check.value.allChecked;
});
const checkState = computed(() => {
  return JSON.parse(JSON.stringify(check.value.checkedList));
});
/**********************************************************
 * Lifecycle Hook
 *********************************************************/
onMounted(() => {});

/**********************************************************
 * Event Handlers
 *********************************************************/
// 모달 닫기
function closeModal() {
  emits("close", {status: false});
}

function handleGoSubmitter(result) {
  router.push({
    name: RoutePaths.WorkPlace.name,
    params: {
      projectId: result.projectId,
      submitterId: result.submitterId,
    },
  });

  closeModal();
}
</script>

<template>
  <div class="w-full">
    <div class="flex items-center w-full px-6 py-4 border-b border-c-layout">
      <font-awesome-icon icon="magnifying-glass" class="mr-gap-group text-lg" />
      <input
        id="searchInput"
        class="w-full h-[2.5rem]"
        style="max-width: calc(100% - 5rem)"
        type="text"
        v-model="search.keyword"
        placeholder="서랍 자료 검색"
        @keyup.enter="search.handleSearch"
      />
      <button class="close" @click="closeModal">
        <font-awesome-icon icon="xmark" class="text-2xl" />
      </button>
    </div>

    <div class="modal-content relative overflow-y-scroll" style="height: calc(100vh - 20rem)">
      <template v-if="search.isLoading">
        <div class="relative w-full h-full">
          <div
            class="absolute top-0 left-0 right-0 bottom-0 w-full h-full pb-10 overflow-hidden flex flex-col items-center justify-center"
          >
            <div
              class="loader-spinner ease-linear rounded-full border-4 border-gray-200 border-t-blue-500 h-12 w-12 mb-4"
            ></div>
            <span class="text-c-dark">검색...</span>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="0 < search.searchSubmitters.length + search.searchSubmitFiles.length">
          <div class="">
            <div class="flex items-center w-full p-gap-group border-y border-c-layout bg-c-table">
              <div class="leading-6">검색된 참여자 ({{ search.searchSubmitters.length }})</div>
            </div>
            <search-submitter
              v-for="(submitter, idx) in search.searchSubmitters"
              :key="idx"
              :item="submitter"
              class=""
              @onGoSubmitter="handleGoSubmitter"
            ></search-submitter>
          </div>
          <div class="my-gap-section border-b border-dashed border-c-layout"></div>
          <div class="">
            <div class="flex items-center w-full p-gap-group border-y border-c-layout bg-c-table">
              <input
                class="form-check-input"
                id="termsInput"
                type="checkbox"
                v-model="allCheckState"
                @change="check.toggleAll"
              />
              <div class="ml-gap-group leading-6">
                검색된 자료 ({{ search.searchSubmitFiles.length }})
              </div>
            </div>
            <div
              v-for="(file, idx) in search.searchSubmitFiles"
              :key="idx"
              class="flex items-center border-b border-c-layout bg-white row-hover"
            >
              <div class="pl-gap-group">
                <input
                  class="form-check-input"
                  id="termsInput"
                  type="checkbox"
                  v-model="checkState[idx]"
                  @change="check.toggleItem(idx)"
                />
              </div>
              <search-file
                :item="file"
                class="w-full"
                @onGoSubmitter="handleGoSubmitter"
              ></search-file>
            </div>
          </div>
          <div class="h-14"></div>

          <button
            v-if="check.getCheckedIndices().length"
            class="cs-btn cs-btn-line fixed-button"
            @click="search.handleDownload"
          >
            <span class="leading-8">다운로드</span>
          </button>
        </template>
        <template v-else>
          <div class="relative h-full">
            <div class="position-center w-full text-center">
              <div class="relative h-[7rem] m-auto">
                <img :src="Icons.IconSearchFolder" class="position-center w-[7rem] h-[7rem]" />
              </div>
              <p class="mt-gap-section text-lg font-semibold">검색된 자료가 없습니다.</p>
              <p class="mt-gap-group text-c-normal">
                <i class="fi fi-sr-bullet"></i> 참여자, 자료를 검색할 수 있습니다.
              </p>
              <p class="mt-gap-text text-c-normal">
                <i class="fi fi-sr-bullet"></i> 검색된 항목의 오른쪽 화살표를 클릭하면 해당 참여자
                화면으로 이동합니다.
              </p>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.close {
  @apply absolute text-c-normal;
  right: 1.5rem;
}

.fixed-button {
  @apply px-c-btn py-c-btn-sm bg-white text-md shadow-lg;

  position: sticky;
  bottom: 0px;
  float: right;
}
</style>
