<script setup>
import {onMounted, ref} from "vue";
import QRCode from "qrcode";

/**********************************************************
 * Reactive Variables
 *********************************************************/
const emits = defineEmits(["close"]);
const props = defineProps({
  params: {
    type: Object,
    default: {},
  },
});

/**********************************************************
 * Lifecycle Hook
 *********************************************************/
onMounted(() => {
  if (!props.params.projectLink) {
    closeModal();
    return;
  }

  const canvas = document.getElementById("canvas");

  const viewportWidth = window.innerWidth; // 화면 전체 너비
  const canvasWidth = viewportWidth * 0.38; // 70% 계산

  QRCode.toCanvas(canvas, props.params.projectLink, {width: canvasWidth}, function (error) {
    if (error) console.error(error);
    console.log("qrcode success!");
  });
});

/**********************************************************
 * Event Handlers
 *********************************************************/
// 모달 닫기
function closeModal() {
  emits("close", {status: false});
}
</script>

<template>
  <div class="" style="width: calc(100vw - 3rem); height: calc(100vh - 3rem)">
    <div class="modal-title">{{ props.params.projectName }}</div>
    <button class="modal-btn-close z-50" @click.stop="closeModal">
      <font-awesome-icon icon="xmark" class="text-lg" />
    </button>

    <div class="modal-content flex w-full h-full">
      <canvas id="canvas" class="m-auto"></canvas>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
